import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import moment from 'moment';

const prod_domains = [
  // SaaS:
  'app.datapred.com',
  // On-prem:
  // (nothing yet)
];
export const isDev = !prod_domains.includes(window.location.hostname);

export const chartIcons = {
  zoomIn:
    'path://M0 0h24v24H0z M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z',
  zoomOut:
    'path://M0 0h24v24H0z M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
};

export const sortArrayOfObjectsById = (arr) => {
  const clonedArray = Array.from(arr);

  clonedArray.sort(function (a, b) {
    return a.id - b.id;
  });
  return clonedArray;
};

export const sortArrayOfObjectsByStringKeyAsc = (arr, key = '') =>
  arr.sort((a, b) => (b[key] < a[key]) - (b[key] > a[key]));

export const sortArrayOfObjectsByDateKey = (arr, key = '') => {
  const clonedArray = Array.from(arr);

  clonedArray.sort(function (a, b) {
    return Date.parse(a[key]) - Date.parse(b[key]);
  });
  return clonedArray;
};

export const toggleInArray = (arr, item, idKey) => {
  // If the item is an object, pass the 'idKey'
  if (idKey) {
    const itemFound = !!arr.find((i) => i[idKey] === item[idKey]);
    if (itemFound) {
      return arr.filter((i) => {
        return i[idKey] !== item[idKey];
      });
    }
    return [...arr, item];
  }
  // If the item is string or number
  return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
};

export const getStartFromEndWithPeriod = (period, from) => {
  if (!period || period === '7D') {
    return moment(from).subtract(7, 'days');
  } else if (period === '30D') {
    return moment(from).subtract(30, 'days');
  } else if (period === '90D') {
    return moment(from).subtract(90, 'days');
  } else if (period === '1Y') {
    return moment(from).subtract(1, 'years');
  } else if (period === '5Y') {
    return moment(from).subtract(5, 'years');
  } else {
    // "All"
    return moment(new Date()).subtract(10, 'years');
  }
};

export const getEndFromStartWithPeriod = (period, from) => {
  if (!period || period === '7D') {
    return moment(from).add(7, 'days');
  } else if (period === '30D') {
    return moment(from).add(30, 'days');
  } else if (period === '90D') {
    return moment(from).add(90, 'days');
  } else if (period === '1Y') {
    return moment(from).add(1, 'years');
  } else if (period === '5Y') {
    return moment(from).add(5, 'years');
  } else {
    // "All"
    return moment(new Date()).add(10, 'years');
  }
};

export const getStartFromMiddleWithPeriod = (period, from) => {
  if (!period || period === '7D') {
    return moment(from).subtract(4, 'days');
  } else if (period === '30D') {
    return moment(from).subtract(15, 'days');
  } else if (period === '90D') {
    return moment(from).subtract(45, 'days');
  } else if (period === '1Y') {
    return moment(from).subtract(0.5, 'years');
  } else if (period === '5Y') {
    return moment(from).subtract(2.5, 'years');
  } else {
    // "All"
    return moment(new Date()).subtract(5, 'years');
  }
};

export const getEndFromMiddleWithPeriod = (period, from) => {
  if (!period || period === '7D') {
    return moment(from).add(4, 'days');
  } else if (period === '30D') {
    return moment(from).add(15, 'days');
  } else if (period === '90D') {
    return moment(from).add(45, 'days');
  } else if (period === '1Y') {
    return moment(from).add(0.5, 'years');
  } else if (period === '5Y') {
    return moment(from).add(2.5, 'years');
  } else {
    // "All"
    return moment(new Date()).add(5, 'years');
  }
};

export const getMatrixMaxValue = (data = [], key) =>
  Math.max(...data.map((item) => item[key]));

export const getMatrixMinValue = (data = [], key) =>
  Math.min(...data.map((item) => item[key]));

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

export const generatePdfName = (type, marketName, date = new Date()) => {
  const formatedDate = moment(date).format('YYYYMMDD');
  const formatedMarketName = marketName.replace(/[\s-]+/g, '');
  const pdfName = `${type}_${formatedMarketName}_${formatedDate}`;
  return pdfName;
};

// Temp fix for Safari pdf generation
const safariFakeLoadingNodeToPdfBis = async (node) => {
  const elements = document.querySelectorAll(node);
  for (const element of elements) {
    await toPng(element);
    await toPng(element);
  }
};
export const exportNodeToPdf = async (node, name = 'charts') => {
  // Check is the used browser is Safari
  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    await safariFakeLoadingNodeToPdfBis(node);
  }
  const sidePadding = 20;
  let pdfHeight = 20;
  let pdfMaxWidth = 0;
  // top position of image insert
  let top = 20;
  const elements = document.querySelectorAll(node);
  //if no charts to export then no export
  if (elements.length === 0) {
    return;
  }
  // get height and width needed for PDF document
  elements.forEach((element) => {
    pdfHeight += element.offsetHeight;
    element.offsetWidth > pdfMaxWidth && (pdfMaxWidth = element.offsetWidth);
    pdfHeight += 20;
  });

  const doc =
    pdfMaxWidth > pdfHeight
      ? new jsPDF('l', 'px', [pdfMaxWidth, pdfHeight])
      : new jsPDF('p', 'px', [pdfMaxWidth, pdfHeight]);

  let aliasIndex = 1;
  const pageWidth = doc.internal.pageSize.getWidth() - sidePadding * 2;

  for (const element of elements) {
    let elHeight = element.offsetHeight;
    let elWidth = element.offsetWidth;
    const data = await toPng(element);
    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth;
      // resize chart width and heigth proportionally
      elHeight = elHeight * ratio;
      elWidth = elWidth * ratio;
    }
    doc.addImage(
      data,
      'PNG',
      sidePadding,
      top,
      elWidth,
      elHeight,
      `alias-${aliasIndex}`
    );
    top = top + elHeight + 20;
    aliasIndex++;
  }
  doc.save(`${name}.pdf`);
};

// Export target #table_id into a csv; compatible with MUI table
export const downloadTableAsCsv = (table_id, separator = ',') => {
  // Select rows from table_id
  var rows = document.querySelectorAll('table#' + table_id + ' tr');
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll('td, th');
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      var data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/(\s\s)/gm, ' ');
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(separator));
  }
  var csv_string = csv.join('\n');
  // Download it
  var filename =
    'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
  var link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string)
  );
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Add thousands spaces to number
export const formatNumberWithThousandsSpaces = (number) => {
  const reversedNumber = number.toString().split('').reverse();

  let formattedNumber = [];
  for (let i = 0; i < reversedNumber.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedNumber.push(' ');
    }
    formattedNumber.push(reversedNumber[i]);
  }
  return formattedNumber.reverse().join('');
};

export function abbreviateLargeNumbers(number, numberOfDecimals) {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(numberOfDecimals) + 'b';
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(numberOfDecimals) + 'm';
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(numberOfDecimals) + 'k';
  } else if (number === 0) {
    return 0;
  } else {
    return number.toFixed(numberOfDecimals);
  }
}

// Convert number to this format '1 234 567.89'
export const formatNumberToTwoNumbersFloatWithThousandsSpaces = (number) => {
  const splittedValue = number.toFixed(2).toString().split('.');
  const decimalPart = splittedValue[1];
  const reversedIntegerPart = splittedValue[0].split('').reverse();

  let formattedIntegerPart = [];
  for (let i = 0; i < reversedIntegerPart.length; i++) {
    if (i % 3 === 0 && i !== 0) {
      formattedIntegerPart.push(' ');
    }
    formattedIntegerPart.push(reversedIntegerPart[i]);
  }
  return formattedIntegerPart.reverse().join('') + '.' + decimalPart;
};

export const getFutureYearFromDate = (date, years) => {
  const futureDay = moment(date).add(years, 'year');
  const futureYear = futureDay.get('year');
  return futureYear;
};
export const getPastYearFromDate = (date, years) => {
  const pastDay = moment(date).subtract(years, 'year');
  const pastYear = pastDay.get('year');
  return pastYear;
};

export const generateSituationPeriods = (selectedSituationDate) => {
  const situationPeriods = [
    {
      id: 'Y-1',
      label: getPastYearFromDate(selectedSituationDate, 1).toString(),
    },
    { id: 'Y', label: moment(selectedSituationDate).get('year').toString() },
    {
      id: 'Y+1',
      label: getFutureYearFromDate(selectedSituationDate, 1).toString(),
    },
    {
      id: 'Y+2',
      label: getFutureYearFromDate(selectedSituationDate, 2).toString(),
    },
    {
      id: 'Y+3',
      label: getFutureYearFromDate(selectedSituationDate, 3).toString(),
    },
    {
      id: 'dateRange',
      label: 'Date Range',
      isDateRange: true,
    },
  ];
  return situationPeriods;
};
export const generateBenchmarkingDashboardPeriods = (selectedSituationDate) => {
  const situationPeriods = [
    {
      id: 'Y-4',
      label: getPastYearFromDate(selectedSituationDate, 4).toString(),
    },
    {
      id: 'Y-3',
      label: getPastYearFromDate(selectedSituationDate, 3).toString(),
    },
    {
      id: 'Y-2',
      label: getPastYearFromDate(selectedSituationDate, 2).toString(),
    },
    {
      id: 'Y-1',
      label: getPastYearFromDate(selectedSituationDate, 1).toString(),
    },
    { id: 'Y', label: moment(selectedSituationDate).get('year').toString() },

    { id: 'all', label: 'All' },
    {
      id: 'dateRange',
      label: 'Date range',
      isDateRange: true,
    },
  ];
  return situationPeriods;
};

export const formatDateToEndOfDay = (date) =>
  moment.utc(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');

export const isCurrency = (currencyShortcode) => {
  const isoCurrcency = [
    'EUR',
    'USD',
    'GBP',
    'NOK',
    'PLN',
    'DKK',
    'SEK',
    'MYR',
    'JPY',
    'CNY',
  ];
  return isoCurrcency.includes(currencyShortcode);
};

/* To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
// package doesn't seems to be maintained
export const toTitleCase = (str) => {
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|off|on|or|per|so|the|to|up|v.?|vs.?|via|yet)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return str
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' ||
          (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join('');
};
//export const valueLabelFormat = (value) => `${value * 100}%`;
export const valueLabelFormat = (value) => `${Math.round(value * 100)}%`;

//Trading rules dates validation

const months = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

const parseDate = (day, yearOffset) => {
  const [dayOfMonth, month] = day.split(' ');
  const currentYear = new Date().getFullYear();
  const year = currentYear + yearOffset;
  return new Date(year, months[month], parseInt(dayOfMonth));
};

export const validateDatesYear = (startDay, startYear, endDay, endYear) => {
  const startDate = parseDate(startDay, startYear);
  const endDate = parseDate(endDay, endYear);
  return endDate >= startDate;
};
export const validateYears = (startYear, endYear) => {
  return endYear >= startYear;
};

export const validateCoveragesDates = (startDay, endDay, year) => {
  const startDate = parseDate(startDay, 0);
  const endDate = parseDate(endDay, year);
  return endDate >= startDate;
};

export const validateMinMax = (minValue, maxValue) => {
  return minValue >= maxValue;
};
export const hashCode = (str) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return hash;
};

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useData } from 'src/services/data/useData';
//import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { logo } = useData();

  //const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  const renderTenantLogo = (
    <Box
      component="img"
      src={logo}
      sx={{
        width: 180,
        height: '100%',
        maxHeight: '70px',
        display: 'inline-flex',
        cursor: 'pointer',
        objectFit: 'contain',
        ...sx,
      }}
    />
  );

  const datapredLogo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 180,
        height: 24,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        width="100%"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_104_1078)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 23.9787H1.384V12.7351H0V23.9787ZM2.768 23.9787H4.15111V0.0622559H2.768V23.9787ZM8.30222 23.9787H9.68622V1.47203H8.30311L8.30222 23.9787ZM5.53511 23.9787H6.91911V2.87914H5.53511V23.9787ZM11.0702 0.0622559V1.47114C16.7929 1.47114 21.4489 6.20892 21.4489 12.0311C21.4489 17.8543 16.7929 22.5911 11.0702 22.5911V24C17.5556 24 22.8329 18.6311 22.8329 12.0311C22.8329 5.43203 17.5556 0.0631448 11.0702 0.0631448"
            fill="#212B36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1467 12.0311C13.1467 12.1991 13.1262 12.3618 13.0898 12.5183L14.4364 12.8427C14.4969 12.5823 14.5298 12.3103 14.5298 12.0311C14.5298 10.0907 12.9778 8.51114 11.0702 8.51114V9.91914C12.2151 9.91914 13.1458 10.8667 13.1458 12.0311M18.6809 12.0311C18.6809 13.3565 18.352 14.6045 17.7724 15.696L18.992 16.3627C19.699 15.0285 20.0674 13.5411 20.0649 12.0311C20.0649 6.98492 16.0293 2.87914 11.0702 2.87914V4.28714C15.2667 4.28714 18.6809 7.76092 18.6809 12.0311Z"
            fill="#212B36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9138 12.0311C15.9156 12.8032 15.7368 13.565 15.3917 14.2557C15.0466 14.9464 14.5447 15.5468 13.9262 16.0089L14.7413 17.1449C15.5367 16.5509 16.1821 15.7791 16.626 14.8912C17.0699 14.0033 17.2999 13.0238 17.2978 12.0311C17.2978 8.53781 14.504 5.69514 11.0702 5.69514V7.10314C13.7413 7.10314 15.9138 9.31381 15.9138 12.0311ZM85.2862 5.74759V24H79.4044V5.74759H72.0533V0.0631448H92.6364V5.74759H85.2862ZM160.146 1.49959V22.5636H165.85C171.732 22.5636 176.2 17.8063 176.2 12.0311C176.2 6.2267 171.702 1.49959 165.85 1.49959H160.146ZM158.676 0.0622559H165.85C172.348 0.0622559 177.641 5.32803 177.641 12.0303C177.641 18.6436 172.348 23.9983 165.85 23.9983H158.676V0.0631448L158.676 0.0622559ZM68.0551 0.0622559H61.9982L52.5591 24H58.8222L64.9973 6.55559L67.32 13.9458H63.1973L61.256 19.4214H69.0551L70.5244 24H76.9049L68.0551 0.0631448M103.28 0.0622559H97.2231L87.7849 24H94.048L100.222 6.55559L102.546 13.9458H98.4222L96.4818 19.4214H104.281L105.751 24H112.132L103.28 0.0631448M42.6213 0.0622559H33.7111V24H39.592V5.7467H42.6213C46.6782 5.7467 48.8258 8.7387 48.8258 12.0311C48.8258 15.2018 46.8258 18.3129 42.6213 18.3129H40.408V24H42.6213C49.1191 24 54.6178 18.6445 54.6178 12.032C54.6178 5.00092 49.1191 0.0640336 42.6213 0.0640336M119.334 0.0622559H112.601V24H114.071V1.4987H119.012C122.392 1.4987 124.804 3.65248 124.804 6.61426C124.804 9.36714 122.511 11.7609 119.012 11.7609H114.887V13.1076H119.334C123.304 13.1076 126.333 10.3849 126.333 6.58492C126.333 2.81514 123.569 0.0622559 119.334 0.0622559ZM141.652 6.5867C141.652 2.81603 138.888 0.0622559 134.654 0.0622559H127.92V24H129.39V1.4987H134.331C137.712 1.4987 140.123 3.65248 140.123 6.61426C140.123 9.36714 137.829 11.7609 134.331 11.7609H130.206V13.1076H135.154L140.005 23.9983H141.652L136.536 12.8978C139.535 12.1805 141.652 9.75648 141.652 6.58492M157.147 0.0622559H143.709V24H157.147V22.5636H145.18V1.49959H157.147V0.0622559Z"
            fill="#212B36"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145.996 12.7494H153.09V11.3129H145.996V12.7494Z"
            fill="#212B36"
          />
        </g>
        <defs>
          <clipPath id="clip0_104_1078">
            <rect width="180" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );

  const LogoComponent =
    logo && logo !== 'default' && Object.keys(logo).length > 0
      ? renderTenantLogo
      : datapredLogo;

  if (disabledLink) {
    return LogoComponent;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {LogoComponent}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;

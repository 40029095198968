import moment from 'moment';
import momentBusinessDay from 'moment-business-days';
import { useCallback } from 'react';
import {
  generateSituationPeriods,
  getEndFromMiddleWithPeriod,
  getStartFromEndWithPeriod,
  getStartFromMiddleWithPeriod,
  sortArrayOfObjectsByDateKey,
} from 'src/utils/utils';
import { useAuth } from '../auth/useAuth';
import useDataActions from '../data/actions';
import { useDataDispatch, useDataState } from '../data/context';
import useDataQueries from '../data/queries';

export const useSituation = () => {
  const {
    situation,
    loadingStates,
    marketsList,
    selectedMarket,
    selectedSituationDate,
    marketDemands,
    marketTransactions,
    marketTransactionsAveragePrice,
    marketReferenceContract,
    marketContracts,
    marketReferenceContractOverview,
    marketTransactionsPeriod,
    marketDemandSelectedPeriod,
    situationPeriods,
    marketsListGraph,
    situationDashBoardSelectedPeriod,
    transactionsStats,
    reportingSummaryGlobalCoverage,
    reportingSummaryGlobalTransaction,
  } = useDataState();

  const dispatch = useDataDispatch();

  const { authenticatedRequest } = useAuth();

  const {
    getConsumptionCsv,
    getTimeSerieXlsx,
    getMarketsList,
    getMarketTransactionsList,
    getMarketContractsList,
    getTransactionsStats,
    getOneTimeSeries,
    getTimeSerieData,
    getTimeSerieStats,
    getTransactionsCsv,
    getCurrency,
    getDemandSituationList,
    postBulkDemandValuesList,
    postDemandInstance,
    postBulkImportTransactions,
    postBulkImportConsumption,
    getBulkImportTransactionsTemplate,
    putTransaction,
    deleteTransaction,
    postTransaction,
    getTargetPriceHistory,
    postTargetPriceHistory,
    getMarketsListGraphs,
    getBulkImportConsumptionTemplate,
    getDemandData,
    getMarketDemandsList,
    getDemandInstance,
    getUnitsInstance,
    getDemandValueList,
    getReportingSummaryGlobalCoverage,
    getReportingSummaryGlobalTransaction,
    getCommodity,
  } = useDataQueries(authenticatedRequest);

  const { setLoading, setMultiData } = useDataActions(dispatch);

  const fetchInitialSituationData = useCallback(
    async (selectedDate) => {
      setLoading('fetchInitialSituationData', true);
      getMarketsList()
        .then((marketsList) => {
          return getMarketsListGraphs().then((marketsListGraph) => {
            return {
              marketsList: marketsList,
              marketsListGraph: marketsListGraph.results,
            };
          });
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        })
        .then((data) => {
          let _selectedMarket = {};
          Object.keys(selectedMarket)?.length === 0
            ? (_selectedMarket = data.marketsList[0])
            : (_selectedMarket = selectedMarket);

          if (_selectedMarket) {
            const marketContractsPromise = getMarketContractsList(
              _selectedMarket?.id
            )
              .then((_selectedMarketContracts) => {
                return _selectedMarketContracts;
              })
              .then(async (_selectedMarketContracts) => {
                let currency;
                let selectedMarketUnit;
                if (_selectedMarketContracts.length === 0) {
                  const selectedMarketCommodity = await getCommodity(
                    _selectedMarket.commodity
                  );
                  selectedMarketUnit =
                    selectedMarketCommodity &&
                    selectedMarketCommodity.unit &&
                    (await getUnitsInstance(selectedMarketCommodity.unit));
                  currency = _selectedMarket.currency;
                } else {
                  currency = _selectedMarketContracts[0]?.currency;
                }

                return getCurrency(currency).then((currency) => {
                  const marketReferenceContract = {
                    ..._selectedMarketContracts[0],
                    currency: currency,
                  };

                  if (
                    _selectedMarketContracts.length === 0 &&
                    selectedMarketUnit
                  ) {
                    marketReferenceContract.commodity_unit_name =
                      selectedMarketUnit.name;
                    marketReferenceContract.contract_unit_name =
                      selectedMarketUnit.name;
                  }

                  return {
                    marketContracts: _selectedMarketContracts,
                    marketReferenceContract: marketReferenceContract,
                  };
                });
              });

            Promise.all([
              marketContractsPromise.catch((error) => console.log(error)),
            ])
              .then((values) => {
                const new_data = Object.assign({}, ...values);
                return {
                  ...data,
                  ...new_data,
                };
              })
              .then((data) => {
                const _situationPeriods =
                  generateSituationPeriods(selectedDate);
                setMultiData({
                  selectedMarket: _selectedMarket,
                  selectedSituationDate: selectedDate,
                  situationPeriods: _situationPeriods,
                  situationDashBoardSelectedPeriod: _situationPeriods.find(
                    (i) => i.id === 'Y'
                  ),
                  ...data,
                });
                setLoading('fetchInitialSituationData', false);
              })
              .catch((error) => console.log(error));
          } else {
            setLoading('fetchInitialSituationData', false);
          }
        });
    },
    [
      getCurrency,
      getMarketContractsList,
      getMarketsList,
      getMarketsListGraphs,
      selectedMarket,
      setLoading,
      setMultiData,
      getCommodity,
      getUnitsInstance,
    ]
  );

  const onMarketChange = useCallback(
    async (_selectedMarket) => {
      setLoading('onMarketChange', true);
      if (_selectedMarket) {
        const marketContractsPromise = getMarketContractsList(
          _selectedMarket?.id
        )
          .then((_selectedMarketContracts) => {
            return _selectedMarketContracts;
          })
          .then(async (_selectedMarketContracts) => {
            let currency;
            let selectedMarketUnit;
            if (_selectedMarketContracts.length === 0) {
              const selectedMarketCommodity = await getCommodity(
                _selectedMarket.commodity
              );
              selectedMarketUnit =
                selectedMarketCommodity &&
                selectedMarketCommodity.unit &&
                (await getUnitsInstance(selectedMarketCommodity.unit));
              currency = _selectedMarket.currency;
            } else {
              currency = _selectedMarketContracts[0]?.currency;
            }

            return getCurrency(currency).then((currency) => {
              const marketReferenceContract = {
                ..._selectedMarketContracts[0],
                currency: currency,
              };

              if (_selectedMarketContracts.length === 0 && selectedMarketUnit) {
                marketReferenceContract.commodity_unit_name =
                  selectedMarketUnit.name;
                marketReferenceContract.contract_unit_name =
                  selectedMarketUnit.name;
              }

              return {
                marketContracts: _selectedMarketContracts,
                marketReferenceContract: marketReferenceContract,
              };
            });
          });

        Promise.all([
          marketContractsPromise.catch((error) => console.log(error)),
        ])
          .then((values) => {
            const new_data = Object.assign({}, ...values);
            return {
              ...new_data,
            };
          })
          .then((data) => {
            setMultiData({
              selectedMarket: _selectedMarket,
              ...data,
            });
            setLoading('onMarketChange', false);
          })
          .catch((error) => console.log(error));
      } else {
        setLoading('onMarketChange', false);
      }
    },
    [
      getCurrency,
      getMarketContractsList,
      setLoading,
      setMultiData,
      getCommodity,
      getUnitsInstance,
    ]
  );

  const setMarketDemandInstance = useCallback(
    async (marketId, demandName, demandCommodity, demandUnit) => {
      const result = await postDemandInstance(
        marketId,
        demandName,
        demandCommodity,
        demandUnit
      );
      return result;
    },
    [postDemandInstance]
  );

  const getMarketDemands = useCallback(
    async (
      marketId,
      startDateRange,
      endDateRange,
      asOfDate,
      samplingFrequency = '1D'
    ) => {
      return getMarketDemandsList(marketId)
        .then((marketDemandsList) => {
          let demandsIds = [];
          if (!marketDemandsList.length) {
            return demandsIds;
          }

          for (const marketDemand of marketDemandsList) {
            demandsIds.push(marketDemand.id);
          }
          return demandsIds;
        })
        .then(async (demandsIds) => {
          let demands = [];
          if (!demandsIds?.length) {
            return demands;
          }

          for (const demandId of demandsIds) {
            try {
              let demand = await getDemandInstance(demandId);

              let unit = await getUnitsInstance(demand.unit);
              demands.push({ ...demand, unitName: unit.name });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return demands;
        })
        .then(async (demands) => {
          let values = [];
          if (!demands.length) {
            return values;
          }

          for (const demand of demands) {
            try {
              let demandValues = await getDemandValueList(
                demand,
                asOfDate,
                '',
                ''
              );
              const sortedDemandValues = sortArrayOfObjectsByDateKey(
                demandValues,
                'start_date'
              );

              const formatedStartDateRange = startDateRange
                ? moment(startDateRange).format('YYYY-MM-DD')
                : null;

              const formatedEndDateRange = endDateRange
                ? moment(endDateRange).format('YYYY-MM-DD')
                : null;
              let {
                valuesHedgedListPerContract: demandHedgedValues,
                demandStats,
              } = await getDemandData(
                demand,
                asOfDate,
                formatedStartDateRange,
                formatedEndDateRange,
                samplingFrequency
              );
              const sortedDemandHedgedValues = sortArrayOfObjectsByDateKey(
                demandHedgedValues,
                'start_date'
              );

              values.push({
                ...demand,
                values: sortedDemandValues,
                hedged: sortedDemandHedgedValues,
                stats: demandStats,
              });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return values;
        });
    },
    [
      getDemandData,
      getDemandInstance,
      getDemandValueList,
      getMarketDemandsList,
      getUnitsInstance,
    ]
  );

  const onSituationDateSelected = useCallback(
    async (selectedDate) => {
      setLoading('onSituationDateSelected', true);
      // Fetch target overview
      const endDate = momentBusinessDay(selectedDate).toISOString();
      const startDate = momentBusinessDay(selectedDate)
        .businessSubtract(2, 'days')
        .toISOString();
      const marketReferenceContractOverviewPromise = getTimeSerieStats(
        marketReferenceContract.series,
        startDate,
        endDate
      )
        .then((timeSerieStats) => {
          if (Object.keys(timeSerieStats)?.length === 0) {
            return {
              marketReferenceContractOverview: {
                lastPrice: 0,
                priceChange: 0,
                changeRate: 0,
              },
            };
          }

          return {
            marketReferenceContractOverview: {
              lastPrice: timeSerieStats?.stats.latest_value || 0,
              priceChange: timeSerieStats?.stats?.diff || 0,
              changeRate: timeSerieStats?.stats?.pct_change,
            },
          };
        })
        .then(async (data) => {
          const marketReferenceContractLastPrice =
            data.marketReferenceContractOverview.lastPrice;

          const middleDateDemand = moment(selectedDate).format('YYYY-MM-DD');
          const startDateDemand = getStartFromMiddleWithPeriod(
            '1Y',
            middleDateDemand
          ).format('YYYY-MM-DD');
          const endDateDemand = getEndFromMiddleWithPeriod(
            '1Y',
            middleDateDemand
          ).format('YYYY-MM-DD');

          let marketDemandsList = await getMarketDemands(
            selectedMarket?.id,
            startDateDemand,
            endDateDemand,
            middleDateDemand
          );
          let values = [];
          if (!marketDemandsList.length) {
            let newDemand = await setMarketDemandInstance(
              selectedMarket,
              marketReferenceContract.unit
            );
            newDemand.hedged = [];
            newDemand.values = [];
            newDemand.unitName = marketReferenceContract?.contract_unit_name;
            marketDemandsList = [newDemand];
          }
          const startDate = moment(selectedDate)
            .startOf('year')
            .format('YYYY-MM-DD');
          const enDate = moment(selectedDate)
            .endOf('year')
            .format('YYYY-MM-DD');
          const situationDate = moment(selectedDate).format('YYYY-MM-DD');

          let targetPrice = 0;
          for (const demand of marketDemandsList) {
            try {
              let targetPriceHistory = await getTargetPriceHistory(
                demand.market,
                selectedDate
              );

              // Check if a default targetPrice if define in the targetPriceHistory for the selected period
              const selectedStartDate =
                moment(startDate)
                  .startOf('year')
                  .format('YYYY-MM-DDTHH:mm:ssz') + 'Z';
              const defaultTargetPrice = targetPriceHistory.find(
                (el) => el.start_date === selectedStartDate
              );

              targetPrice = defaultTargetPrice
                ? defaultTargetPrice.value
                : marketReferenceContractLastPrice;
              let demandSituationList = await getDemandSituationList(
                demand.id,
                targetPrice,
                startDate,
                enDate,
                situationDate
              );

              values.push({
                ...demand,
                situation: demandSituationList,
                situationTargetPrice: {
                  current: targetPrice,
                  history: targetPriceHistory,
                },
              });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return {
            ...data,
            marketDemands: values,
          };
        })
        .catch(() => {
          return {
            marketDemands: [],
            marketReferenceContractOverview: {
              lastPrice: 0,
              priceChange: 0,
              changeRate: 0,
            },
          };
        });

      Promise.all([
        marketReferenceContractOverviewPromise.catch((error) =>
          console.log(error)
        ),
      ]).then((values) => {
        const data = Object.assign({}, ...values);
        const _situationPeriods = generateSituationPeriods(selectedDate);
        setMultiData({
          ...data,
          selectedSituationDate: selectedDate,
          situationPeriods: _situationPeriods,
          situationDashBoardSelectedPeriod: _situationPeriods.find(
            (i) => i.id === 'Y'
          ),
        });
        setLoading('onSituationDateSelected', false);
      });
    },
    [
      setLoading,
      getTimeSerieStats,
      marketReferenceContract.series,
      marketReferenceContract.unit,
      marketReferenceContract?.contract_unit_name,
      selectedMarket,
      getMarketDemands,
      setMarketDemandInstance,
      getTargetPriceHistory,
      getDemandSituationList,
      setMultiData,
    ]
  );

  const onMarketDemandPeriodSelected = useCallback(
    (asOfDate, startDate, endDate, marketDemand, samplingFrequency = '1D') => {
      setLoading('onMarketDemandPeriodSelected', true);
      let _marketDemands = marketDemands;
      const formatedStartDate = startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : null;
      const formatedEndDate = endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : null;
      /* Fetch demands */
      getDemandData(
        marketDemand,
        moment(asOfDate).toISOString(),
        formatedStartDate,
        formatedEndDate,
        samplingFrequency
      ).then(
        ({
          valuesHedgedListPerContract: demandValuesHedgedResult,
          demandStats,
        }) => {
          if (!demandValuesHedgedResult) {
            return;
          } else {
            const sortedDemandValuesHedgedList = sortArrayOfObjectsByDateKey(
              demandValuesHedgedResult,
              'start_date'
            );
            for (const currentDemand of _marketDemands) {
              if (currentDemand.id === marketDemand.id) {
                currentDemand.hedged = sortedDemandValuesHedgedList;
                currentDemand.stats = demandStats;
              }
            }
          }
          setMultiData({
            marketDemands: _marketDemands,
          });
          setLoading('onMarketDemandPeriodSelected', false);
        }
      );
    },
    [setLoading, marketDemands, getDemandData, setMultiData]
  );

  const onMarketConsumptionMutation = useCallback(
    async (demand, consumptionData, samplingFrequency = '1D') => {
      setLoading('onMarketConsumptionMutation', true);
      let postTransactionResponse = await postBulkDemandValuesList(
        demand,
        consumptionData
      );
      if (postTransactionResponse?.error) {
        return postTransactionResponse;
      }
      const demandStartDate = getStartFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');

      const demandEndDate = getEndFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');
      getMarketDemands(
        selectedMarket?.id,
        moment(demandStartDate).toISOString(),
        moment(demandEndDate).toISOString(),
        moment(selectedSituationDate).toISOString(),
        samplingFrequency
      )
        .then(async (demands) => {
          let values = [];
          if (!demands.length) {
            return values;
          }
          const startDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .startOf('year')
            .format('YYYY-MM-DD');

          const enDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .endOf('year')
            .format('YYYY-MM-DD');
          const situationDate = moment(selectedSituationDate).format(
            'YYYY-MM-DD'
          );
          for (const demand of demands) {
            try {
              let targetPriceHistory = await getTargetPriceHistory(
                demand.market,
                selectedSituationDate
              );
              const updatedSituationTargetPrice = {
                ...marketDemands?.[0]?.situationTargetPrice,
                history: targetPriceHistory,
              };
              const targetPrice =
                marketDemands?.[0]?.situationTargetPrice.current ||
                marketReferenceContractOverview.lastPrice;

              let demandSituationList = await getDemandSituationList(
                demand.id,
                targetPrice,
                startDate,
                enDate,
                situationDate
              );

              values.push({
                ...demand,
                situation: demandSituationList,
                situationTargetPrice: updatedSituationTargetPrice,
              });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return {
            marketDemands: values,
          };
        })
        .then((data) => {
          setMultiData({
            marketDemands: data.marketDemands,
          });
        });

      setLoading('onMarketConsumptionMutation', false);
      return postTransactionResponse;
    },
    [
      setLoading,
      postBulkDemandValuesList,
      marketDemandSelectedPeriod.id,
      selectedSituationDate,
      getMarketDemands,
      selectedMarket?.id,
      situationDashBoardSelectedPeriod?.label,
      getTargetPriceHistory,
      marketDemands,
      marketReferenceContractOverview.lastPrice,
      getDemandSituationList,
      setMultiData,
    ]
  );

  const onMarketTransactionsPeriodSelected = useCallback(
    (startDate, endDate) => {
      setLoading('onMarketTransactionsPeriodSelected', true);
      if (Object.keys(selectedMarket)?.length === 0) {
        return;
      }
      /* Fetch past purchases */
      getMarketTransactionsList(selectedMarket?.id, startDate, endDate).then(
        async (marketTransactions) => {
          const _transactionsStats = await getTransactionsStats(
            selectedMarket?.id,
            moment(startDate).toISOString(),
            moment(endDate).toISOString()
          ).catch((e) => {
            return {
              error: true,
              message: e.message,
            };
          });
          setMultiData({
            transactionsStats: _transactionsStats,
            marketTransactions: marketTransactions,
          });
        }
      );
      setLoading('onMarketTransactionsPeriodSelected', false);
    },
    [
      setLoading,
      selectedMarket,
      getMarketTransactionsList,
      setMultiData,
      getTransactionsStats,
    ]
  );

  const onUploadBulkImportTransaction = useCallback(
    async (bulkImportFile, marketId) => {
      setLoading('onUploadBulkImportTransaction', true);
      const result = await postBulkImportTransactions(bulkImportFile, marketId);
      if (result.error) {
        setLoading('onUploadBulkImportTransaction', false);
        return result;
      }
      const demandStartDate = getStartFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');

      const demandEndDate = getEndFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');
      getMarketDemands(
        selectedMarket?.id,
        moment(demandStartDate).toISOString(),
        moment(demandEndDate).toISOString(),
        moment(selectedSituationDate).toISOString()
      )
        .then(async (demands) => {
          let values = [];
          if (!demands.length) {
            return values;
          }
          const startDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .startOf('year')
            .format('YYYY-MM-DD');

          const enDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .endOf('year')
            .format('YYYY-MM-DD');
          const situationDate = moment(selectedSituationDate).format(
            'YYYY-MM-DD'
          );
          for (const demand of demands) {
            try {
              let targetPriceHistory = await getTargetPriceHistory(
                demand.market,
                selectedSituationDate
              );
              const updatedSituationTargetPrice = {
                ...marketDemands?.[0]?.situationTargetPrice,
                history: targetPriceHistory,
              };
              let demandSituationList = await getDemandSituationList(
                demand.id,
                marketDemands?.[0]?.situationTargetPrice.current,
                startDate,
                enDate,
                situationDate
              );

              values.push({
                ...demand,
                situation: demandSituationList,
                situationTargetPrice: updatedSituationTargetPrice,
              });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return {
            marketDemands: values,
          };
        })
        .then((data) => {
          setMultiData({
            marketDemands: data.marketDemands,
          });
          setLoading('onUploadBulkImportTransaction', false);
        });

      return result;
    },
    [
      setLoading,
      postBulkImportTransactions,
      marketDemandSelectedPeriod.id,
      selectedSituationDate,
      getMarketDemands,
      selectedMarket?.id,
      situationDashBoardSelectedPeriod?.label,
      getTargetPriceHistory,
      marketDemands,
      getDemandSituationList,
      setMultiData,
    ]
  );

  const onUploadBulkImportConsumption = useCallback(
    async (bulkImportFile, demandId) => {
      setLoading('onUploadBulkImportConsumption', true);
      const result = await postBulkImportConsumption(bulkImportFile, demandId);
      if (result.error) {
        setLoading('onUploadBulkImportConsumption', false);
        return result;
      }
      const demandStartDate = getStartFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');

      const demandEndDate = getEndFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');

      getMarketDemands(
        selectedMarket?.id,
        moment(demandStartDate).toISOString(),
        moment(demandEndDate).toISOString(),
        moment(selectedSituationDate).toISOString()
      )
        .then(async (demands) => {
          let values = [];
          if (!demands.length) {
            return values;
          }
          const startDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .startOf('year')
            .format('YYYY-MM-DD');
          const enDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .endOf('year')
            .format('YYYY-MM-DD');

          const situationDate = moment(selectedSituationDate).format(
            'YYYY-MM-DD'
          );
          for (const demand of demands) {
            try {
              let targetPriceHistory = await getTargetPriceHistory(
                demand.market,
                selectedSituationDate
              );
              const updatedSituationTargetPrice = {
                ...marketDemands?.[0]?.situationTargetPrice,
                history: targetPriceHistory,
              };
              let demandSituationList = await getDemandSituationList(
                demand.id,
                marketDemands?.[0]?.situationTargetPrice.current,
                startDate,
                enDate,
                situationDate
              );

              values.push({
                ...demand,
                situation: demandSituationList,
                situationTargetPrice: updatedSituationTargetPrice,
              });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return {
            marketDemands: values,
          };
        })
        .then((data) => {
          setMultiData({
            marketDemands: data.marketDemands,
          });
          setLoading('onUploadBulkImportConsumption', false);
        });

      return result;
    },
    [
      getDemandSituationList,
      getMarketDemands,
      getTargetPriceHistory,
      marketDemandSelectedPeriod.id,
      marketDemands,
      postBulkImportConsumption,
      selectedMarket?.id,
      selectedSituationDate,
      setLoading,
      setMultiData,
      situationDashBoardSelectedPeriod?.label,
    ]
  );

  const onTransactionManaging = useCallback(
    async (transactionData, selectedPeriod, type = null) => {
      setLoading('onTransactionManaging', true);
      let postTransactionResponse =
        type === 'edit'
          ? await putTransaction(transactionData)
          : type === 'delete'
            ? await deleteTransaction(transactionData?.id)
            : await postTransaction(transactionData);
      if (postTransactionResponse?.error) {
        setLoading('onTransactionManaging', false);
        return postTransactionResponse;
      }

      const selectedPeriodId = selectedPeriod?.id || 'All';

      // Fetch marketTransactions & transactionsStats according to the current selected period
      let transactionStartDate = null;
      let transactionEnDate = null;
      if (selectedPeriod?.isDateRange) {
        transactionStartDate = moment(
          selectedPeriod.dateRangeStartDate
        ).toISOString();
        transactionEnDate = moment(
          selectedPeriod.dateRangeEndDate
        ).toISOString();
      } else {
        transactionEnDate = moment(selectedSituationDate).toISOString();
        transactionStartDate = getStartFromEndWithPeriod(
          selectedPeriodId,
          transactionEnDate
        ).toISOString();
      }
      onMarketTransactionsPeriodSelected(
        transactionStartDate,
        transactionEnDate
      );
      const demandStartDate = getStartFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');

      const demandEndDate = getEndFromMiddleWithPeriod(
        marketDemandSelectedPeriod.id,
        selectedSituationDate
      ).format('YYYY-MM-DD');

      getMarketDemands(
        selectedMarket?.id,
        moment(demandStartDate).toISOString(),
        moment(demandEndDate).toISOString(),
        moment(selectedSituationDate).toISOString()
      )
        .then(async (demands) => {
          let values = [];
          if (!demands.length) {
            return values;
          }

          const startDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .startOf('year')
            .format('YYYY-MM-DD');
          const enDate = moment(
            new Date(situationDashBoardSelectedPeriod?.label)
          )
            .endOf('year')
            .format('YYYY-MM-DD');
          const situationDate = moment(selectedSituationDate).format(
            'YYYY-MM-DD'
          );
          for (const demand of demands) {
            try {
              let targetPriceHistory = await getTargetPriceHistory(
                demand.market,
                selectedSituationDate
              );

              const updatedSituationTargetPrice = {
                ...marketDemands?.[0]?.situationTargetPrice,
                history: targetPriceHistory,
              };

              let demandSituationList = await getDemandSituationList(
                demand.id,
                marketDemands?.[0]?.situationTargetPrice.current,
                startDate,
                enDate,
                situationDate
              );

              if (demandSituationList) {
                demandSituationList = {
                  ...demandSituationList,
                  targetPriceHistory: targetPriceHistory,
                };
              }

              values.push({
                ...demand,
                situation: demandSituationList,
                situationTargetPrice: updatedSituationTargetPrice,
              });
            } catch (e) {
              return {
                error: true,
                message: e.message,
              };
            }
          }
          return {
            marketDemands: values,
          };
        })
        .then((data) => {
          setMultiData({
            marketDemands: data.marketDemands,
          });
        });
      setLoading('onTransactionManaging', false);
    },
    [
      setLoading,
      putTransaction,
      deleteTransaction,
      postTransaction,
      selectedSituationDate,
      selectedMarket?.id,
      marketDemandSelectedPeriod.id,
      onMarketTransactionsPeriodSelected,
      getMarketDemands,
      situationDashBoardSelectedPeriod?.label,
      getTargetPriceHistory,
      marketDemands,
      getDemandSituationList,
      setMultiData,
    ]
  );
  const onBulkImportTransactionTemplate = useCallback(async () => {
    return await getBulkImportTransactionsTemplate();
  }, [getBulkImportTransactionsTemplate]);

  const onBulkImportConsumptionTemplate = useCallback(
    async (format) => {
      return await getBulkImportConsumptionTemplate(format);
    },
    [getBulkImportConsumptionTemplate]
  );

  const onTargetPriceUpdate = useCallback(
    async (startDate, endDate, targetPrice) => {
      setLoading('onTargetPriceUpdate', true);
      const situationDate = moment(selectedSituationDate).format('YYYY-MM-DD');
      if (targetPrice) {
        let targetPriceHistory = await getTargetPriceHistory(
          marketDemands?.[0]?.market,
          selectedSituationDate
        );
        let demandSituationList = await getDemandSituationList(
          marketDemands?.[0]?.id,
          targetPrice,
          startDate,
          endDate,
          situationDate
        );

        const newMarketDemands = [...marketDemands];
        newMarketDemands[0].situation = demandSituationList;
        newMarketDemands[0].situationTargetPrice = {
          current: targetPrice,
          history: targetPriceHistory,
        };
        setMultiData({
          marketDemands: newMarketDemands,
        });
      }
      setLoading('onTargetPriceUpdate', false);
    },
    [
      setLoading,
      selectedSituationDate,
      getTargetPriceHistory,
      marketDemands,
      getDemandSituationList,
      setMultiData,
    ]
  );

  const setTargetPriceHistory = useCallback(
    async (bulkTargetPriceHistory, marketId) => {
      setLoading('setTargetPriceHistory', true);
      return await postTargetPriceHistory(
        bulkTargetPriceHistory,
        marketId
      ).then((res) => {
        const updatedSituationTargetPrice = {
          current: null,
          history: res.data.values,
        };
        const newMarketDemands = [...marketDemands];
        newMarketDemands[0].situationTargetPrice = updatedSituationTargetPrice;
        setMultiData({
          marketDemands: newMarketDemands,
        });
        setLoading('setTargetPriceHistory', false);
        return res;
      });
    },
    [marketDemands, postTargetPriceHistory, setLoading, setMultiData]
  );

  const onSituationSummaryPeriodSelected = useCallback(
    async (startDate, endDate, targetPrice) => {
      setLoading('onSituationSummaryPeriodSelected', true);
      const situationDate = moment(selectedSituationDate).format('YYYY-MM-DD');
      if (targetPrice) {
        let demandSituationList = await getDemandSituationList(
          marketDemands?.[0]?.id,
          targetPrice,
          startDate,
          endDate,
          situationDate
        );
        const newMarketDemands = [...marketDemands];
        newMarketDemands[0].situation = demandSituationList;
        setMultiData({
          marketDemands: newMarketDemands,
        });
      }
      setLoading('onSituationSummaryPeriodSelected', false);
    },
    [
      getDemandSituationList,
      marketDemands,
      selectedSituationDate,
      setLoading,
      setMultiData,
    ]
  );
  const getUpdatedSituationData = useCallback(
    async (startDate, endDate, targetPrice) => {
      setLoading('getUpdatedSituationData', true);
      const situationDate = moment(selectedSituationDate).format('YYYY-MM-DD');
      if (targetPrice || targetPrice === 0) {
        let targetPriceHistory = await getTargetPriceHistory(
          marketDemands?.[0]?.market,
          selectedSituationDate
        );
        let demandSituationList = await getDemandSituationList(
          marketDemands?.[0]?.id,
          targetPrice,
          startDate,
          endDate,
          situationDate
        );

        const newMarketDemands = [...marketDemands];
        if (newMarketDemands[0]) {
          newMarketDemands[0].situation = demandSituationList;
          newMarketDemands[0].situationTargetPrice = {
            current: targetPrice,
            history: targetPriceHistory,
          };
        }

        setMultiData({
          marketDemands: newMarketDemands,
        });
      }
      setLoading('getUpdatedSituationData', false);
    },
    [
      getDemandSituationList,
      getTargetPriceHistory,
      marketDemands,
      selectedSituationDate,
      setLoading,
      setMultiData,
    ]
  );

  const getUpdatedReportingSummaryGlobalCoverage = useCallback(
    async (demandId, asOfDate, startDateRange, endDateRange, targetPrice) => {
      setLoading('getUpdatedReportingSummaryGlobalCoverage', true);
      await getReportingSummaryGlobalCoverage(
        demandId,
        moment(asOfDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        startDateRange,
        endDateRange,
        targetPrice
      ).then((summaryData) =>
        setMultiData({
          reportingSummaryGlobalCoverage: summaryData,
        })
      );
      setLoading('getUpdatedReportingSummaryGlobalCoverage', false);

      return;
    },
    [getReportingSummaryGlobalCoverage, setLoading, setMultiData]
  );

  const getUpdatedReportingSummaryGlobalTransaction = useCallback(
    async (asOfDate, startDateRange, endDateRange, marketId) => {
      setLoading('getUpdatedReportingSummaryGlobalTransaction', true);
      await getReportingSummaryGlobalTransaction(
        moment(asOfDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        startDateRange,
        endDateRange,
        marketId
      ).then((summaryData) =>
        setMultiData({
          reportingSummaryGlobalTransaction: summaryData,
        })
      );
      setLoading('getUpdatedReportingSummaryGlobalTransaction', false);

      return;
    },
    [getReportingSummaryGlobalTransaction, setLoading, setMultiData]
  );

  return {
    // State
    situation,
    selectedMarket,
    selectedSituationDate,
    marketsList,
    marketDemands,
    marketTransactions,
    marketTransactionsAveragePrice,
    loadingStates,
    marketContracts,
    marketReferenceContract,
    marketReferenceContractOverview,
    marketTransactionsPeriod,
    situationPeriods,
    situationDashBoardSelectedPeriod,
    marketsListGraph,
    transactionsStats,
    reportingSummaryGlobalCoverage,
    reportingSummaryGlobalTransaction,
    // Actions
    getConsumptionCsv,
    getTimeSerieXlsx,
    getTransactionsCsv,
    fetchInitialSituationData,
    getOneTimeSeries,
    onMarketChange,
    onSituationDateSelected,
    getTimeSerieData,
    getTimeSerieStats,
    onMarketDemandPeriodSelected,
    onMarketConsumptionMutation,
    setMarketDemandInstance,
    onMarketTransactionsPeriodSelected,
    onUploadBulkImportTransaction,
    onUploadBulkImportConsumption,
    onBulkImportTransactionTemplate,
    onBulkImportConsumptionTemplate,
    onTransactionManaging,
    onTargetPriceUpdate,
    setTargetPriceHistory,
    onSituationSummaryPeriodSelected,
    getUpdatedSituationData,
    getDemandSituationList,
    getUpdatedReportingSummaryGlobalCoverage,
    getUpdatedReportingSummaryGlobalTransaction,
  };
};

import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { buildContractsByCommodities } from 'src/services/data/utils';
import { formatDateToEndOfDay, isDev } from 'src/utils/utils';
import { API_URL } from '../../config';
import { formatTimeSeriesDetails, outputsTypesMap } from './format';

export default function useDataQueries(authenticatedRequest) {
  // Used to cancel/abort faulty requests
  const controller = useMemo(() => {
    return new AbortController();
  }, []);

  const getContractDeliveryDates = useCallback(
    (contractId, productionDate) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/estimate-delivery-dates?production-date=${productionDate}`;
      return authenticatedRequest('get', url)
        .then((res) => {
          return res.data;
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getContractsAndCommodities = useCallback(() => {
    // Get contracts
    const url = `${API_URL}/procurement/contracts?ordering=name&page_size=10000`;
    return authenticatedRequest('get', url)
      .then((res) => res.data?.results)
      .then((contracts) => {
        // Build an array of commodities ids
        const commoditiesIds = [];
        contracts.forEach((contract) => {
          if (!commoditiesIds.includes(contract.underlying_commodity)) {
            commoditiesIds.push(contract.underlying_commodity);
          }
        });

        // Fetch commodities details
        const commodities = [];
        return Promise.all(
          commoditiesIds.map((commodityId) => {
            const url = `${API_URL}/procurement/commodities/${commodityId}`;
            return authenticatedRequest('get', url).then((res) =>
              commodities.push(res.data)
            );
          })
        ).then(() => {
          return {
            contracts,
            commodities,
            contractsByCommodities: buildContractsByCommodities(
              contracts,
              commodities
            ),
          };
        });
      });
  }, [authenticatedRequest]);

  const getProductionRuns = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/production-runs?page_size=10000&complete=true&flow_type=procurement`;
      return authenticatedRequest('get', url).then(
        (res) => res.data?.results || []
      );
    },
    [authenticatedRequest]
  );
  const getScenarioProductionRun = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/production-runs?page_size=10000&complete=true&flow_type=scenario`;
      return authenticatedRequest('get', url).then(
        (res) => res.data?.results || []
      );
    },
    [authenticatedRequest]
  );
  const getScenarioProductionRunOutput = useCallback(
    (scenarioFlowId, scenarioRunId) => {
      const url = `${API_URL}/procurement/flows/${scenarioFlowId}/runs/${scenarioRunId}/outputs`;
      return authenticatedRequest('get', url).then(
        (res) => res.data?.results || []
      );
    },
    [authenticatedRequest]
  );
  const getFlow = useCallback(
    (flowId) => {
      const url = `${API_URL}/procurement/flows/${flowId}`;
      return authenticatedRequest('get', url)
        .then((res) => res.data)
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const deleteFlow = useCallback(
    async (flowId) => {
      const url = `${API_URL}/procurement/flows/${flowId}`;

      return authenticatedRequest('delete', url).then((res) => res);
    },
    [authenticatedRequest]
  );

  const getFlowsWithProductionFlows = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/current-production-flows?flow_type=procurement`;
      return authenticatedRequest('get', url)
        .then((res) => res.data?.results || [])
        .then((flows) => {
          // Additional request for each flow to obtain the modelling_group's id
          return Promise.all(
            flows.map((flow, index) => {
              const url = `${API_URL}/procurement/flows/${flow.production_flow}`;
              return authenticatedRequest('get', url).then((res) => {
                flows[index].productionFlow = res?.data || {};
              });
            })
          ).then(() => flows);
        });
    },
    [authenticatedRequest]
  );

  const getCurrentProductionFlow = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/current-production-flows?flow_type=procurement`;
      return authenticatedRequest('get', url)
        .then((res) => {
          return res.data.results;
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getProductionFlows = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/production-flows?flow_type=procurement`;
      return authenticatedRequest('get', url)
        .then((res) => res.data?.results || [])
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );
  const getScenariosProductionFlows = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}/production-flows?flow_type=scenario`;
      return authenticatedRequest('get', url)
        .then((res) => res.data?.results || [])
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getContracts = useCallback(
    async (page = undefined) => {
      const pageOptions = page ? `?page=${page}` : '';
      const url = `${API_URL}/procurement/contracts?page_size=10000&ordering=name${pageOptions}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );

  const getContract = useCallback(
    (contractId) => {
      const url = `${API_URL}/procurement/contracts/${contractId}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );

  const getTimeSeries = useCallback(
    (page = undefined) => {
      const pageOptions = page ? `?page=${page}` : '';
      const url = `${API_URL}/procurement/time-series?page_size=10000&ordering=name${pageOptions}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );

  const getOneTimeSeries = useCallback(
    (timeSeriesId) => {
      const url = `${API_URL}/procurement/time-series/${timeSeriesId}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );

  const getFlowTimeSeries = useCallback(
    async (flow_id, contractTargetId) => {
      const url = `${API_URL}/procurement/flows/${flow_id}/timeseries?target=${contractTargetId}`;
      return authenticatedRequest('get', url)
        .then((res) => res?.data?.results || [])
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getTimeSerieStats = useCallback(
    (id, startDate, endDate) => {
      const url =
        `${API_URL}/procurement/time-series/${id}/stats` +
        `?start_date=${startDate}&end_date=${endDate}`;

      return authenticatedRequest('get', url).then((res) => {
        return { stats: res.data };
      });
    },
    [authenticatedRequest]
  );

  const getTimeSerieData = useCallback(
    (id, startDate, endDate, minPoints) => {
      let url = `${API_URL}/procurement/time-series/${id}/data`;
      const hadDateRange = startDate && endDate;
      if (hadDateRange) {
        url = `${url}?start_date=${startDate}&end_date=${endDate}`;
      }
      if (!minPoints) {
        minPoints = -1;
      }
      const prefix = hadDateRange ? '&' : '?';
      url = `${url}${prefix}min_samples=${minPoints}`;

      return authenticatedRequest('get', url).then((res) =>
        formatTimeSeriesDetails({ data: res.data })
      );
    },
    [authenticatedRequest]
  );

  const getTimeSerieDataAndStats = useCallback(
    async (id, startDate, endDate, minPoints) => {
      let promises = [getTimeSerieData(id, startDate, endDate, minPoints)];

      if (startDate && endDate) {
        promises.push(getTimeSerieStats(id, startDate, endDate));
      }

      return Promise.all(promises).then((timeSerieData) => {
        return Object.assign({}, ...timeSerieData);
      });
    },
    [getTimeSerieData, getTimeSerieStats]
  );

  const getRunOutputs = useCallback(
    (runId, targetId, flowLongTermHorizonThreshold) => {
      let outputUrl;
      if (flowLongTermHorizonThreshold) {
        outputUrl = `${API_URL}/procurement/runs/${runId}/outputs?corridor_horizon_threshold=${flowLongTermHorizonThreshold}`;
      } else {
        outputUrl = `${API_URL}/procurement/runs/${runId}/outputs`;
      }
      if (targetId) {
        outputUrl += flowLongTermHorizonThreshold
          ? `&target=${targetId}`
          : `?target=${targetId}`;
      }
      return authenticatedRequest('get', outputUrl)
        .then((res) => res?.data?.results || [])
        .then((outputs) => {
          const excluded_output_types = [
            'price-bands',
            'predictions',
            'feature-importances',
          ];
          const cleaned_outputs = outputs.filter(
            (outputs) => !excluded_output_types.includes(outputs.output_type)
          );

          // Default empty data
          const outputsWithData = {
            bands: { data: [] },
            trends: { data: [] },
            corridor: { data: [] },
          };

          // Since we are using the smoothed price driver endpoints, we don't need
          // to get the whole data from `feature-importances` but we need the ids.
          const featureImportances = outputs.filter(
            (outputs) => outputs.output_type === 'feature-importances'
          );
          if (featureImportances.length > 0) {
            outputsWithData['featureImportances'] = featureImportances[0];
          }

          cleaned_outputs.forEach((output) => {
            let dataKey = output.output_type;
            const outputKey = outputsTypesMap[dataKey];
            if (dataKey === 'price-corridors') {
              dataKey = 'price_corridors';
            }
            outputsWithData[outputKey] = {
              ...output,
              output_type: outputKey,
              data: output[dataKey],
            };
          });

          return outputsWithData;
        });
    },
    [authenticatedRequest]
  );

  const getCurrency = useCallback(
    (currencyId) => {
      const url = `${API_URL}/procurement/currencies/${currencyId}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );
  const getUnitsInstance = useCallback(
    (unitId) => {
      const url = `${API_URL}/procurement/units/${unitId}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );

  const getCommodity = useCallback(
    (commodityId) => {
      const url = `${API_URL}/procurement/commodities/${commodityId}`;
      return authenticatedRequest('get', url).then((res) => res.data);
    },
    [authenticatedRequest]
  );

  const getFlowDrivers = useCallback(
    (flowId) => {
      const url = `${API_URL}/procurement/flows/${flowId}/drivers/`;
      return authenticatedRequest('get', url).then(
        (res) => res.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getFlowHorizons = useCallback(
    (flowId) => {
      const url = `${API_URL}/procurement/flows/${flowId}/horizons`;
      return authenticatedRequest('get', url).then((res) => res.data || []);
    },
    [authenticatedRequest]
  );

  const getCurrentPriceDrivers = useCallback(
    (flow, run, output) => {
      const url = `${API_URL}/procurement/flows/${flow}/runs/${run}/outputs/${output}/smoothed-current-price-drivers/?smoothing_window=5`;
      return authenticatedRequest('get', url).then(
        (res) => res.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getWindowPriceDrivers = useCallback(
    (flow, run, output, startDate) => {
      const url = `${API_URL}/procurement/flows/${flow}/runs/${run}/outputs/${output}/smoothed-window-price-drivers/?window_start_date=${startDate}&smoothing_window=5`;
      return authenticatedRequest('get', url).then(
        (res) => res.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getVolatilityHistorical = useCallback(
    (timeSerieId, volatilityWindow, endDate) => {
      const url = `${API_URL}/procurement/time-series/${timeSerieId}/volatility?window=${volatilityWindow}&end_date=${endDate}`;

      return authenticatedRequest('get', url)
        .then((res) => res?.data || [])
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const postBuyometerTask = useCallback(
    async (
      flowId,
      timeSeriesId,
      maxDeltaPrice,
      maxBuyingHorizon,
      startDate,
      endDate,
      smoothingWindow
    ) => {
      const url = `${API_URL}/procurement/flows/${flowId}/buy-hold-indicator`;

      const body = {
        target_id: timeSeriesId,
        max_delta_price: maxDeltaPrice,
        max_buy_horizon: maxBuyingHorizon,
        start_date: startDate,
        end_date: endDate,
        smoothing_window: smoothingWindow,
      };

      const buyometerComputingTaskId = authenticatedRequest(
        'post',
        url,
        {},
        body
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            status: err.response.status,
            data: err.response.data,
          };
        });

      return buyometerComputingTaskId;
    },
    [authenticatedRequest]
  );

  const getBuyometerResults = useCallback(
    async (flowId, buyometerTaskId) => {
      let counter = 0;

      return new Promise((resolve) => {
        (function myFunc() {
          const url = `${API_URL}/procurement/flows/${flowId}/buy-hold-indicator/${buyometerTaskId}`;
          if (buyometerTaskId) {
            authenticatedRequest('get', url, {
              signal: AbortSignal.timeout(3500),
            })
              .then((response) => {
                if (response && response.status === 200) {
                  return resolve(response.data);
                } else if (response && response.status === 204) {
                  controller.abort('Computation not done');
                  throw Error('Retry fetching computation results');
                }
              })
              .catch(() => {
                counter++;
                if (counter < 3) {
                  return setTimeout(myFunc, 4500);
                } else {
                  return resolve([]);
                }
              });
          } else {
            counter++;
            if (counter < 3) {
              return setTimeout(myFunc, 4500);
            }
          }
        })();
      });
    },
    [authenticatedRequest, controller]
  );

  const getContractGraphs = useCallback(async () => {
    const url = `${API_URL}/procurement/contract-graphs`;
    const contractGraphs = authenticatedRequest('get', url).then(
      (res) => res.data
    );
    if (!contractGraphs) {
      if (isDev) {
        console.error("Query 'getContractGraphs, contract graph not found'");
      }
    }
    return contractGraphs;
  }, [authenticatedRequest]);

  const getContractGraphNodes = useCallback(async () => {
    const url = `${API_URL}/procurement/contract-graph-nodes/`;
    const contractGraphNodes = authenticatedRequest('get', url).then(
      (res) => res.data?.results || []
    );

    if (!contractGraphNodes) {
      if (isDev) {
        console.error(
          "Query 'getContractGraphNodes, contract nodes not found'"
        );
      }
    }
    return contractGraphNodes;
  }, [authenticatedRequest]);

  const getDecisionConfigs = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/decision-configs`;

    const decisionConfigs = authenticatedRequest('get', url).then(
      (res) => res?.data?.results || []
    );

    return decisionConfigs;
  }, [authenticatedRequest]);

  const getProductionDecisionFlows = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/current-decision-production-flows`;

    const prodDecisionFlows = authenticatedRequest('get', url).then(
      (res) => res?.data?.results || []
    );

    return prodDecisionFlows;
  }, [authenticatedRequest]);

  const getParameterSetsByDecisionConfig = useCallback(
    async (decisionConfigId) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/parameter-set?page_size=10000`;
      const parameterSets = authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
      return parameterSets;
    },
    [authenticatedRequest]
  );

  const getParameterSets = useCallback(
    async (run, computed) => {
      if (computed) {
        let flowId;
        let runStatus;
        const parameterSets = authenticatedRequest(
          'get',
          `${API_URL}/procurement/runs/${run.id}/outputs?output_type=ConstraintSolverStatus`
        )
          .then((res) => res?.data?.results[0] || [])
          .then((decisionOuput) => {
            flowId = decisionOuput?.flow;
            runStatus = decisionOuput.constraint_solver_status;

            return authenticatedRequest(
              'get',
              `${API_URL}/procurement-tenant/decision-configs/${run.decision_configuration}/parameter-set/${decisionOuput.parameter_set}`
            ).then((res) => res?.data || []);
          })
          .then((parameterSetOutput) => {
            let result = [
              { ...parameterSetOutput, flowId: flowId, runStatus: runStatus },
            ];
            return result;
          });
        return parameterSets;
      } else {
        const url = `${API_URL}/procurement-tenant/decision-configs/${run.decision_configuration}/parameter-set?page_size=10000`;
        const parameterSets = authenticatedRequest('get', url).then(
          (res) => res?.data?.results || []
        );
        return parameterSets;
      }
    },
    [authenticatedRequest]
  );
  const getUserParameterSetSchema = useCallback(
    async (decisionConfigId) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/?format=json`;

      const parameterSetSchema = authenticatedRequest('get', url).then(
        (res) => res?.data?.user_parameter_set_schema || []
      );

      return parameterSetSchema;
    },
    [authenticatedRequest]
  );

  const getDecisionConfigurationInstance = useCallback(
    async (decisionConfigId) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}`;

      const decisionConfiguration = authenticatedRequest('get', url).then(
        (res) => res?.data || null
      );

      return decisionConfiguration;
    },
    [authenticatedRequest]
  );

  const getDecisionStandardConfigurationTemplates = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/strategy-templates-rendered`;

    const decisionStandardConfigurations = authenticatedRequest(
      'get',
      url
    ).then((res) => res?.data?.results || null);

    return decisionStandardConfigurations;
  }, [authenticatedRequest]);

  const isNameError = (res) => {
    if (
      res &&
      res.includes('duplicate key' && 'Key (name, decision_config_id)')
    )
      return [
        'A strategy with this name already exists, please choose another Strategy Name',
      ];
    return;
  };

  const putParameterSet = useCallback(
    async (decisionConfigId, parameterSetId, parameterSetData) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/parameter-set/${parameterSetId}`;
      const parameterSet = authenticatedRequest(
        'put',
        url,
        {},
        parameterSetData
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            errorMessage:
              isNameError(err.response?.data[0]) ||
              err.response.data?.parameter_set,
          };
        });
      return parameterSet;
    },
    [authenticatedRequest]
  );
  const postParameterSet = useCallback(
    async (decisionConfigId, parameterSetData) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/parameter-set/`;
      const parameterSet = authenticatedRequest(
        'post',
        url,
        {},
        parameterSetData
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          console.log('err', err);

          return {
            error: true,
            message: err.message,
            errorMessage:
              isNameError(err.response.data[0]) ||
              err.response.data?.parameter_set,
          };
        });
      return parameterSet;
    },
    [authenticatedRequest]
  );

  const deleteParameterSet = useCallback(
    async (decisionConfigId, parameterSetId) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/parameter-set/${parameterSetId}`;
      return authenticatedRequest('delete', url).then((res) => res);
    },
    [authenticatedRequest]
  );

  const postParameterSetEligibility = useCallback(
    async (decisionConfigId, parameterSetData) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/parameter-set/verify-standard-parameter-set`;
      const parameterSet = authenticatedRequest(
        'post',
        url,
        {},
        parameterSetData
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            errorMessage:
              isNameError(err.response.data.detail?.name) ||
              err.response.data?.detail.parameter_set,
          };
        });
      return parameterSet;
    },
    [authenticatedRequest]
  );

  const getRecommendationRuns = useCallback(
    async (recommendationProblemId) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${recommendationProblemId}/production-runs?page_size=10000&complete=true&ordering=production_date,integration_date`;

      const recommendationRuns = authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );

      return recommendationRuns;
    },
    [authenticatedRequest]
  );

  const getRecommendationTemplates = useCallback(
    async (decisionConfigId) => {
      const url = `${API_URL}/procurement-tenant/decision-configs/${decisionConfigId}/templates?ordering=order`;

      const recommendationTemplates = authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );

      return recommendationTemplates;
    },
    [authenticatedRequest]
  );

  const getRecommendationDataToDisplay = useCallback(
    (runId, displayedParameterSets, templates) => {
      let dataToDisplay = {};
      return authenticatedRequest(
        'get',
        `${API_URL}/procurement/runs/${runId}/outputs?output_type=decision`
      )
        .then((res) => res?.data?.results[0] || [])
        .then((decisionOuput) => {
          let promises = [];
          for (const template of templates) {
            dataToDisplay[template.id] = {};
            for (const parameterSet of displayedParameterSets) {
              promises.push(
                authenticatedRequest(
                  'get',
                  `${API_URL}/procurement/runs/${runId}/outputs/${decisionOuput.id}/parameter-sets/${parameterSet.id}/templates/${template.id}/rendered`
                )
                  .then((res) => res?.data || [])
                  .then((data) => {
                    return {
                      template_id: template.id,
                      parameterset_id: parameterSet.id,
                      data: data,
                    };
                  })
              );
            }
          }
          return Promise.all(promises);
        })
        .then((data_list) => {
          for (const { template_id, parameterset_id, data } of data_list) {
            dataToDisplay[template_id][parameterset_id] = data;
          }
          return dataToDisplay;
        });
    },
    [authenticatedRequest]
  );

  const getLiveRecommendationDataToDisplay = useCallback(
    (runIdArray, templates, isStandard = true) => {
      let dataToDisplay = {};
      let promises = [];
      for (const run of runIdArray) {
        promises.push(
          authenticatedRequest(
            'get',
            `${API_URL}/procurement/runs/${run.id}/outputs?output_type=decision`
          )
            .then((res) => {
              return res?.data?.results[0] || [];
            })
            .then((decisionOuput) => {
              return {
                decisionOuputId: decisionOuput.id,
                decisionOuputParameterSet: decisionOuput.parameter_set,
                data: run,
              };
            })
        );
      }
      return Promise.all(promises).then((runsData) => {
        let promises = [];
        for (const run of runsData) {
          if (run.decisionOuputParameterSet) {
            // Since Standard decision challenge do not use templates
            templates = isStandard
              ? [{ id: run.decisionOuputParameterSet }]
              : templates;
            for (const template of templates) {
              dataToDisplay[template.id] = {};
              const url = isStandard
                ? `${API_URL}/procurement/runs/${run.data.id}/outputs/${run.decisionOuputId}/standard-decision-rendered`
                : `${API_URL}/procurement/runs/${run.data.id}/outputs/${run.decisionOuputId}/parameter-sets/${run.decisionOuputParameterSet}/templates/${template.id}/rendered`;
              promises.push(
                authenticatedRequest('get', url)
                  .then((res) => res?.data || [])
                  .then((data) => {
                    data.runData = run.data;
                    return {
                      template_id: template.id,
                      parameterset_id: run.decisionOuputParameterSet,
                      data: data,
                    };
                  })
              );
            }
          }
        }
        return Promise.all(promises).then((data_list) => {
          for (const { template_id, parameterset_id, data } of data_list) {
            dataToDisplay[template_id][parameterset_id] = data;
          }
          return dataToDisplay;
        });
      });
    },
    [authenticatedRequest]
  );

  const getClientList = useCallback(async () => {
    const url = `${API_URL}/user-management/clients`;

    return authenticatedRequest('get', url).then(
      (res) => res?.data?.results || []
    );
  }, [authenticatedRequest]);

  const setClientActive = useCallback(
    async (id) => {
      const url = `${API_URL}/user-management/clients/${id}/set_active_client`;

      return authenticatedRequest('get', url).then((res) => res || []);
    },
    [authenticatedRequest]
  );

  const getUser = useCallback(
    async (username) => {
      const url = `${API_URL}/user-management/users?username=${encodeURIComponent(
        username
      )}`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results[0] || []
      );
    },
    [authenticatedRequest]
  );
  const getUserList = useCallback(async () => {
    const url = `${API_URL}/user-management/users`;

    return authenticatedRequest('get', url).then(
      (res) => res?.data?.results || []
    );
  }, [authenticatedRequest]);

  const getUserInstance = useCallback(
    async (userId) => {
      const url = `${API_URL}/user-management/users/${userId}`;

      return authenticatedRequest('get', url).then((res) => res?.data);
    },
    [authenticatedRequest]
  );

  const activateUser = useCallback(
    async (userId) => {
      const url = `${API_URL}/user-management/users/${userId}/activate`;

      return authenticatedRequest('get', url).then((res) => {
        return res;
      });
    },
    [authenticatedRequest]
  );

  const deactivateUser = useCallback(
    async (userId) => {
      const url = `${API_URL}/user-management/users/${userId}/deactivate`;

      return authenticatedRequest('get', url).then((res) => {
        return res;
      });
    },
    [authenticatedRequest]
  );

  const setTenantAdmin = useCallback(
    async (userId) => {
      const url = `${API_URL}/user-management/users/${userId}/set_tenant_admin`;

      return authenticatedRequest('get', url).then((res) => {
        return res;
      });
    },
    [authenticatedRequest]
  );
  const unsetTenantAdmin = useCallback(
    async (userId) => {
      const url = `${API_URL}/user-management/users/${userId}/unset_tenant_admin`;

      return authenticatedRequest('get', url).then((res) => {
        return res;
      });
    },
    [authenticatedRequest]
  );

  const getUserSetting = useCallback(
    async (userId, settingKey) => {
      if (!userId) {
        return {};
      }
      const url = `${API_URL}/user-management/users/${userId}/settings/${settingKey}`;

      return authenticatedRequest('get', url)
        .then((res) => JSON.parse(res?.data?.value) || {})
        .catch((err) => {
          console.error(err.message);
          return {
            message: err.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getUserScopeList = useCallback(
    async (userId) => {
      if (!userId) {
        return [];
      }

      const url = `${API_URL}/user-management/users/${userId}/scopes`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const postUserScope = useCallback(
    async (userId, scopeId) => {
      if (!userId) {
        return [];
      }
      const body = {
        scope: scopeId,
      };

      const url = `${API_URL}/user-management/users/${userId}/scopes`;

      return authenticatedRequest('post', url, {}, body).then((res) => res);
    },
    [authenticatedRequest]
  );

  const getScopeList = useCallback(async () => {
    const url = `${API_URL}/user-management/scopes`;

    return authenticatedRequest('get', url).then(
      (res) => res?.data?.results || []
    );
  }, [authenticatedRequest]);

  const getTenantScopeList = useCallback(
    async (tenantId) => {
      const url = `${API_URL}/user-management/clients/${tenantId}/scopes`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getScopeInstance = useCallback(
    async (scopeId) => {
      const url = `${API_URL}/user-management/scopes/${scopeId}`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const deleteScopeInstance = useCallback(
    async (userId, scopeId) => {
      const url = `${API_URL}/user-management/users/${userId}/scopes/${scopeId}`;

      return authenticatedRequest('delete', url).then((res) => res);
    },
    [authenticatedRequest]
  );

  const getUsers = useCallback(async () => {
    return getUserList().then(async (usersList) => {
      let users = [];
      if (!usersList.length) {
        return users;
      }
      for (const user of usersList) {
        try {
          let userScope = await getUserScopeList(user.id);
          users.push({ ...user, scope: userScope });
        } catch (e) {
          return {
            error: true,
            message: e.message,
          };
        }
      }
      return users;
    });
  }, [getUserList, getUserScopeList]);

  const createUser = useCallback(
    async (userData) => {
      if (!userData) {
        return [];
      }
      const body = {
        ...userData,
      };

      const url = `${API_URL}/user-management/users`;

      return authenticatedRequest(
        'post',
        url,
        {
          validateStatus: (status) => {
            return status <= 400;
          },
        },
        body
      )
        .then((res) => {
          if (res.status !== 201) {
            const error = new Error(res?.data?.[0]);

            error.code = res.status;
            return Promise.reject(error || 'something went wrong');
          }
          return res;
        })
        .catch((e) => {
          return Promise.reject(e) || 'Something went wrong';
        });
    },
    [authenticatedRequest]
  );

  const setUserSetting = useCallback(
    async (userId, settingKey, payload) => {
      if (!userId) {
        return {};
      }
      const url = `${API_URL}/user-management/users/${userId}/settings/${settingKey}`;

      return authenticatedRequest(
        'put',
        url,
        {},
        { value: JSON.stringify(payload) }
      ).then((res) => res?.data?.value || {});
    },
    [authenticatedRequest]
  );

  const sendInvitationEmail = useCallback(
    async (userId) => {
      const url = `${API_URL}/user-management/users/${userId}/send_invitation`;
      return authenticatedRequest('get', url).then((res) => {
        return res;
      });
    },
    [authenticatedRequest]
  );

  const getContractBasedAlertConditions = useCallback(
    async (alertId) => {
      const url = `${API_URL}/procurement/contract-based-triggers/${alertId}/conditions`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getTimeSeriesBasedAlertConditions = useCallback(
    async (alertId) => {
      const url = `${API_URL}/procurement/time-series-based-triggers/${alertId}/conditions`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getTimeSeriesBasedAlertDiffusionList = useCallback(
    async (alertId) => {
      const url = `${API_URL}/procurement/time-series-based-triggers/${alertId}/diffusion-list/`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getContractBasedAlertDiffusionList = useCallback(
    async (alertId) => {
      const url = `${API_URL}/procurement/contract-based-triggers/${alertId}/diffusion-list/`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const deleteContractBasedAlertDiffusionList = useCallback(
    async (alertId, diffusionListId) => {
      const url = `${API_URL}/procurement/contract-based-triggers/${alertId}/diffusion-list/${diffusionListId}`;
      const deletedDiffusionList = authenticatedRequest(
        'delete',
        url,
        {},
        alertId
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return deletedDiffusionList;
    },
    [authenticatedRequest]
  );

  const deleteTimeSeriesBasedAlertDiffusionList = useCallback(
    async (alertId, diffusionListId) => {
      const url = `${API_URL}/procurement/time-series-based-triggers/${alertId}/diffusion-list/${diffusionListId}`;
      const deleteDiffusionList = authenticatedRequest('delete', url, {})
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return deleteDiffusionList;
    },
    [authenticatedRequest]
  );

  const deleteTransaction = useCallback(
    async (transactionId) => {
      const url = `${API_URL}/procurement-tenant/transactions/${transactionId}`;
      const deletedTransaction = authenticatedRequest(
        'delete',
        url,
        {},
        transactionId
      )
        .then((res) => {
          return res?.data || [];
        })
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return deletedTransaction;
    },
    [authenticatedRequest]
  );

  const postTimeSeriesBasedAlertDiffusionList = useCallback(
    async (alertId, userId) => {
      const url = `${API_URL}/procurement/time-series-based-triggers/${alertId}/diffusion-list`;
      const body = {
        user: userId,
      };
      const diffusionList = authenticatedRequest('post', url, {}, body)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return diffusionList;
    },
    [authenticatedRequest]
  );
  const putTimeSeriesBasedAlertDiffusionListChannel = useCallback(
    async (diffusionList) => {
      const channelUrl = `${API_URL}/procurement/time-series-based-triggers/${diffusionList.time_series_based_trigger}/diffusion-list/${diffusionList.id}/channels/${diffusionList.is_subscribed_to_email_channel_id}`;

      const updatedChannel = authenticatedRequest('get', channelUrl)
        .then((res) => res?.data || {})
        .then((channel) => {
          const body = {
            ...channel,
            is_subscribed: !channel.is_subscribed,
          };
          return authenticatedRequest('put', channelUrl, {}, body)
            .then((res) => res?.data || [])
            .catch((err) => {
              return {
                message: err.message,
              };
            });
        });
      return updatedChannel;
    },
    [authenticatedRequest]
  );

  const postTimeSeriesBasedAlertDiffusionListChannel = useCallback(
    async (alertId, diffusionListId) => {
      const url = `${API_URL}/procurement/time-series-based-triggers/${alertId}/diffusion-list/${diffusionListId}/channels`;
      const channeltype = 'email';
      const body = {
        channel_type: channeltype,
        is_subscribed: true,
      };
      const channelList = authenticatedRequest('post', url, {}, body)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return channelList;
    },
    [authenticatedRequest]
  );

  const postContractBasedAlertDiffusionList = useCallback(
    async (alertId, userId) => {
      const url = `${API_URL}/procurement/contract-based-triggers/${alertId}/diffusion-list`;
      const body = {
        user: userId,
      };
      const diffusionList = authenticatedRequest('post', url, {}, body)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return diffusionList;
    },
    [authenticatedRequest]
  );

  const postContractBasedAlertDiffusionListChannel = useCallback(
    async (alertId, diffusionListId) => {
      const url = `${API_URL}/procurement/contract-based-triggers/${alertId}/diffusion-list/${diffusionListId}/channels`;
      const channeltype = 'email';
      const body = {
        channel_type: channeltype,
        is_subscribed: true,
      };
      const channelList = authenticatedRequest('post', url, {}, body)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return channelList;
    },
    [authenticatedRequest]
  );

  const putContractBasedAlertDiffusionListChannel = useCallback(
    async (diffusionList) => {
      const channelUrl = `${API_URL}/procurement/contract-based-triggers/${diffusionList.contract_based_trigger}/diffusion-list/${diffusionList.id}/channels/${diffusionList.is_subscribed_to_email_channel_id}`;

      const updatedChannel = authenticatedRequest('get', channelUrl)
        .then((res) => res?.data || {})
        .then((channel) => {
          const body = {
            ...channel,
            is_subscribed: !channel.is_subscribed,
          };
          return authenticatedRequest('put', channelUrl, {}, body)
            .then((res) => res?.data || [])
            .catch((err) => {
              return {
                message: err.message,
              };
            });
        });
      return updatedChannel;
    },
    [authenticatedRequest]
  );

  const getContractBasedAlerts = useCallback(() => {
    const url = `${API_URL}/procurement/contract-based-triggers?page_size=1000`;

    const contractBasedAlerts = authenticatedRequest('get', url)
      .then((res) => res?.data?.results || [])
      .then(async (alerts) => {
        const conditionPromises = [];
        const diffusionListPromises = [];
        alerts.forEach((alertObj) => {
          const conditionsPromise = getContractBasedAlertConditions(
            alertObj?.id
          ).then((res) => {
            return {
              id: alertObj.id,
              condition: res,
            };
          });
          conditionPromises.push(conditionsPromise);
        });
        alerts.forEach((alertObj) => {
          const diffusionListPromise = getContractBasedAlertDiffusionList(
            alertObj?.id
          ).then((res) => {
            return {
              id: alertObj.id,
              diffusionList: res,
            };
          });

          diffusionListPromises.push(diffusionListPromise);
        });

        const conditionsArray = await Promise.all(conditionPromises);
        const diffusionListArray = await Promise.all(diffusionListPromises);
        return alerts.map((alertObj_1) => ({
          ...alertObj_1,
          ...conditionsArray.find(
            (conditions) => conditions.id === alertObj_1.id
          ),
          ...diffusionListArray.find(
            (diffusionList) => diffusionList.id === alertObj_1.id
          ),
        }));
      });
    return contractBasedAlerts;
  }, [
    authenticatedRequest,
    getContractBasedAlertConditions,
    getContractBasedAlertDiffusionList,
  ]);

  const getTimeSeriesBasedAlerts = useCallback(async () => {
    const url = `${API_URL}/procurement/time-series-based-triggers?page_size=1000`;

    const timeSeriesBasedAlerts = authenticatedRequest('get', url)
      .then((res) => res?.data?.results || [])
      .then(async (alerts) => {
        const promises = [];
        const diffusionListPromises = [];
        alerts.forEach((alertObj) => {
          const conditionsPromise = getTimeSeriesBasedAlertConditions(
            alertObj?.id
          ).then((res) => {
            return {
              id: alertObj.id,
              condition: res,
            };
          });
          promises.push(conditionsPromise);
        });
        alerts.forEach((alertObj) => {
          const diffusionListPromise = getTimeSeriesBasedAlertDiffusionList(
            alertObj?.id
          ).then((res) => {
            return {
              id: alertObj.id,
              diffusionList: res,
            };
          });

          diffusionListPromises.push(diffusionListPromise);
        });
        const conditionsArray = await Promise.all(promises);
        const diffusionListArray = await Promise.all(diffusionListPromises);
        return alerts.map((alertObj_1) => ({
          ...alertObj_1,
          ...conditionsArray.find(
            (conditions) => conditions.id === alertObj_1.id
          ),
          ...diffusionListArray.find(
            (diffusionList) => diffusionList.id === alertObj_1.id
          ),
        }));
      });

    return timeSeriesBasedAlerts;
  }, [
    authenticatedRequest,
    getTimeSeriesBasedAlertConditions,
    getTimeSeriesBasedAlertDiffusionList,
  ]);

  const getAlertsConditionTemplates = useCallback(async () => {
    const url = `${API_URL}/procurement/trigger-condition-templates`;

    const alertsConditionTemplates = authenticatedRequest('get', url).then(
      (res) => res?.data?.results || []
    );

    return alertsConditionTemplates;
  }, [authenticatedRequest]);

  const postContractBasedTrigger = useCallback(
    async (alert) => {
      const url = `${API_URL}/procurement/contract-based-triggers`;
      const body = {
        trigger: {
          last_sent: alert.trigger.last_sent,
          status: 'ON',
          template: alert.trigger?.template || 'base',
        },
        available_to_everyone: false,
        contract: alert.contract,
      };
      const contractBasedTrigger = authenticatedRequest('post', url, {}, body)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return contractBasedTrigger;
    },
    [authenticatedRequest]
  );
  const postContractBasedTriggerConditions = useCallback(
    async (alertId, alertData) => {
      const url = `${API_URL}/procurement/contract-based-triggers/${alertId}/conditions`;
      const conditions = alertData?.condition[0];

      if (Object.keys(conditions).length === 0) {
        return;
      }
      const body = {
        sample_window: conditions?.sample_window,
        alert_parameter_set: conditions?.alert_parameter_set
          ? conditions.alert_parameter_set
          : null,
        condition_template: conditions?.condition_template,
      };
      const contractBasedTriggerConditions = authenticatedRequest(
        'post',
        url,
        {},
        body
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return contractBasedTriggerConditions;
    },
    [authenticatedRequest]
  );

  const postTimeSeriesBasedTrigger = useCallback(
    async (alertData) => {
      const url = `${API_URL}/procurement/time-series-based-triggers`;
      const body = {
        trigger: {
          last_sent: alertData.trigger.last_sent,
          status: 'ON',
          template: alertData.trigger?.template || 'base',
        },
        available_to_everyone: false,
        series: alertData.series,
      };
      const timeSeriesBasedTrigger = authenticatedRequest('post', url, {}, body)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return timeSeriesBasedTrigger;
    },
    [authenticatedRequest]
  );

  const postTimeSeriesBasedTriggerConditions = useCallback(
    async (alertId, alertData) => {
      const url = `${API_URL}/procurement/time-series-based-triggers/${alertId}/conditions`;
      const conditions = alertData?.condition[0];
      if (Object.keys(conditions).length === 0) {
        return;
      }
      const body = {
        sample_window: conditions?.sample_window,
        alert_parameter_set: conditions?.alert_parameter_set
          ? conditions.alert_parameter_set
          : null,
        condition_template: conditions?.condition_template,
      };
      const timeSeriesBasedTriggerConditions = authenticatedRequest(
        'post',
        url,
        {},
        body
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
      return timeSeriesBasedTriggerConditions;
    },
    [authenticatedRequest]
  );

  const getRunOutputsbyFeatureImportance = useCallback(
    async (flowId, runId, outputId, horizon) => {
      const url = `${API_URL}/procurement/flows/${flowId}/runs/${runId}/outputs/${outputId}/feature-importances-aggregated?horizon=${horizon.value}&ordering=-contribution`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getContractsWithScenarioProductionFlow = useCallback(async () => {
    const url = `${API_URL}/procurement/current-production-flows?flow_type=scenario&page_size=10000`;

    return authenticatedRequest('get', url)
      .then((res) => res?.data?.results || [])
      .then((flowList) => flowList.map((flow) => flow.contract));
  }, [authenticatedRequest]);

  const getRunScenarioOutput = useCallback(
    async (scenarioFlowId, runId, outputId, contractId) => {
      const url = `${API_URL}/procurement/flows/${scenarioFlowId}/runs/${runId}/outputs/${outputId}/scenario-predictions-adjusted?contract_id=${contractId}`;

      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );

  const getTransactions = useCallback(
    async (recommendationProblemId, startDate, endDate) => {
      const url = `${API_URL}/procurement-tenant/transactions?production_start_date=${startDate}&production_end_date=${endDate}&contains_decision_challenge=${recommendationProblemId}&page_size=10000`;
      return authenticatedRequest('get', url).then(
        (res) => res?.data?.results || []
      );
    },
    [authenticatedRequest]
  );
  const getIsTenantApp = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant`;
    return authenticatedRequest('get', url).then((res) => {
      return res.status || '';
    });
  }, [authenticatedRequest]);

  const getTransactionsAveragePrice = useCallback(
    async (recommendationProblemId, startDate, endDate, contract) => {
      const url = `${API_URL}/procurement-tenant/transaction-stats?production_start_date=${startDate}&production_end_date=${endDate}&contract=${contract.id}&contains_decision_challenge=${recommendationProblemId}`;
      return authenticatedRequest('get', url)
        .then((res) => {
          return (
            {
              contract_id: contract.id,
              contract_name: contract.name,
              ...res.data,
            } || {}
          );
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getPastRecommendations = useCallback(
    async (recommendationProblemId, startDate, endDate) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${recommendationProblemId}/outputs?&is_optimized_quantity=True&horizon=0&production_start_date=${startDate}&production_end_date=${endDate}&decision_live_mode=False&page_size=10000`;
      return authenticatedRequest('get', url)
        .then((res) => res?.data?.results || [])
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        })
        .then((rawPastRecommendations) => {
          if (!rawPastRecommendations.length) {
            return;
          }
          let pastRecommendations = [];
          for (const rawPastRecommendation of rawPastRecommendations) {
            const decision = rawPastRecommendation.decisions[0];

            pastRecommendations.push({
              ...decision,
              flowId: rawPastRecommendation.flow,
              production_date: rawPastRecommendation.production_date,
              runId: rawPastRecommendation.run,
              decision_live_mode: rawPastRecommendation.decision_live_mode,
            });
          }
          return pastRecommendations;
        });
    },
    [authenticatedRequest]
  );

  const getPastRecommendationsByDecisionFactor = useCallback(
    async (recommendationProblemId, startDate, endDate, parameterSetFlowId) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${recommendationProblemId}/outputs?&is_optimized_quantity=True&horizon=0&production_start_date=${startDate}&production_end_date=${endDate}&flow=${parameterSetFlowId}&decision_live_mode=False&page_size=1000&output_type=decision`;
      return authenticatedRequest('get', url)
        .then((res) => res?.data?.results || [])
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        })
        .then((rawPastRecommendations) => {
          if (!rawPastRecommendations?.length) {
            return;
          }
          let pastRecommendations = [];
          for (const rawPastRecommendation of rawPastRecommendations) {
            const decision = rawPastRecommendation.decisions[0];

            pastRecommendations.push({
              ...decision,
              flowId: rawPastRecommendation.flow,
              production_date: rawPastRecommendation.production_date,
              runId: rawPastRecommendation.run,
              decision_live_mode: rawPastRecommendation.decision_live_mode,
            });
          }

          return pastRecommendations;
        });
    },
    [authenticatedRequest]
  );

  const getDemandInstance = useCallback(
    async (demandId) => {
      const url = `${API_URL}/procurement-tenant/demands/${demandId}`;
      try {
        let response = await authenticatedRequest('get', url);
        return response?.data || [];
      } catch (e) {
        return {
          error: true,
          message: e.message,
        };
      }
    },
    [authenticatedRequest]
  );

  const postDemandInstance = useCallback(
    async (market, unit) => {
      const url = `${API_URL}/procurement-tenant/demands`;
      const body = {
        name: market.market_name,
        market: market.id,
        unit: unit,
      };
      const demandInstance = await authenticatedRequest('post', url, {}, body)
        .then((res) => res.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            status: err.response.status,
          };
        });
      return demandInstance;
    },
    [authenticatedRequest]
  );

  const getDemandValueList = useCallback(
    async (demand, asOfDate, startDateRange, endDateRange) => {
      const start_range_date = startDateRange
        ? `&start_range_date=${startDateRange}`
        : '';
      const end_range_date = endDateRange
        ? `&end_range_date=${endDateRange}`
        : '';
      const formatedAsOfDate =
        moment(asOfDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DDTHH:mm:ssz') + 'Z';
      const url = `${API_URL}/procurement-tenant/demands/${demand.id}/values?as_of=${formatedAsOfDate}${start_range_date}${end_range_date}&page_size=10000`;
      try {
        let response = await authenticatedRequest('get', url);
        return response?.data?.results || [];
      } catch (e) {
        return {
          error: true,
          message: e.message,
        };
      }
    },
    [authenticatedRequest]
  );
  const getDemandSituationList = useCallback(
    async (demandId, targetPrice, startDate, endDate, situationDate) => {
      const formatedAsOfDate =
        moment(situationDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DDTHH:mm:ssz') + 'Z';
      let url = `${API_URL}/procurement-tenant/demands/${demandId}/situation?as_of=${formatedAsOfDate}`;
      if (targetPrice) url += `&target_price=${targetPrice}`;
      if (startDate) url += `&start_range_date=${startDate}`;
      if (endDate) url += `&end_range_date=${endDate}`;

      const demandSituationList = authenticatedRequest('get', url)
        .then((res) => res?.data || [])
        .catch(() => {
          return [];
        });

      return demandSituationList;
    },
    [authenticatedRequest]
  );
  const getDemandValuesHedgedListPerContract = useCallback(
    async (
      demand,
      formatedAsOfDate,
      start_range_date,
      end_range_date,
      samplingFrequency = '1D'
    ) => {
      const url = `${API_URL}/procurement-tenant/demands/${demand.id}/hedged-per-contract?as_of=${formatedAsOfDate}${start_range_date}${end_range_date}&sampling_frequency=${samplingFrequency}`;
      try {
        let response = await authenticatedRequest('get', url);
        return response?.data || [];
      } catch (e) {
        return {
          error: true,
          message: e.message,
        };
      }
    },
    [authenticatedRequest]
  );
  const getDemandData = useCallback(
    async (
      demand,
      asOfDate,
      startDateRange,
      endDateRange,
      samplingFrequency = '1D'
    ) => {
      const start_range_date = startDateRange
        ? `&start_range_date=${startDateRange}`
        : '';
      const end_range_date = endDateRange
        ? `&end_range_date=${endDateRange}`
        : '';
      const formatedAsOfDate =
        moment(asOfDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DDTHH:mm:ssz') + 'Z';
      const valuesHedgedListPerContract =
        await getDemandValuesHedgedListPerContract(
          demand,
          formatedAsOfDate,
          start_range_date,
          end_range_date,
          samplingFrequency
        );

      const demandStats = await getDemandSituationList(
        demand?.id,
        null,
        start_range_date,
        end_range_date,
        formatedAsOfDate
      );
      return { valuesHedgedListPerContract, demandStats };
    },
    [getDemandSituationList, getDemandValuesHedgedListPerContract]
  );

  const postBulkDemandValuesList = useCallback(
    async (demand, bulkData) => {
      const url = `${API_URL}/procurement-tenant/demands/${demand.id}/bulk-update`;
      const body = { insert: bulkData.insert, cancel: bulkData.cancel };
      const bulkUpdateData = await authenticatedRequest('post', url, {}, body)
        .then((res) => res || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            status: err.response.status,
            errorMessage: err.response.data,
            whole: err,
          };
        });
      return bulkUpdateData;
    },
    [authenticatedRequest]
  );

  const getBulkImportTransactionsTemplate = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/bulk-import-transactions-form/download_csv_template`;
    const bulkImportTemplate = await authenticatedRequest('get', url)
      .then((res) =>
        URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }))
      )
      .catch((err) => {
        return {
          error: true,
          message: err.message,
        };
      });
    return bulkImportTemplate;
  }, [authenticatedRequest]);

  const getBulkImportConsumptionTemplate = useCallback(
    async (format) => {
      if (format === 'csv') {
        const url = `${API_URL}/procurement-tenant/demands/download_csv_template`;
        const bulkImportTemplate = await authenticatedRequest('get', url)
          .then((res) =>
            URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }))
          )
          .catch((err) => {
            return {
              error: true,
              message: err.message,
            };
          });
        return bulkImportTemplate;
      } else if (format === 'xlsx') {
        const url = `${API_URL}/procurement-tenant/demands/download_excel_template`;
        const bulkImportTemplate = await authenticatedRequest('get', url, {
          responseType: 'arraybuffer',
        }).then((res) => {
          return URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          );
        });
        return bulkImportTemplate;
      }
    },
    [authenticatedRequest]
  );

  const postBulkImportTransactions = useCallback(
    async (bulkImportFile, marketId) => {
      const url = `${API_URL}/procurement-tenant/bulk-import-transactions-form`;
      const formData = new FormData();
      formData.append('file_uploaded', bulkImportFile);
      formData.append('csv_delimiter', ',');
      formData.append('market', marketId);
      //formData.append('decision_challenges', recommendationProblemId); marketId?
      const bulkImportData = await authenticatedRequest(
        'post',
        url,
        { 'Content-Type': 'multipart/form-data' },
        formData
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            status: err.response.status,
            errorMessage: err.response.data,
            whole: err,
          };
        });
      return bulkImportData;
    },
    [authenticatedRequest]
  );

  const postBulkImportConsumption = useCallback(
    async (bulkImportFile, demandId) => {
      const url = `${API_URL}/procurement-tenant/demands/${demandId}/upload_csv`;
      const formData = new FormData();
      formData.append('file_uploaded', bulkImportFile, 'import.csv');
      formData.append('csv_delimiter', ',');

      const bulkImportData = await authenticatedRequest(
        'post',
        url,
        { 'Content-Type': 'multipart/form-data' },
        formData
      )
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            status: err.response.status,
            errorMessage: err.response.data,
            whole: err,
          };
        });
      return bulkImportData;
    },
    [authenticatedRequest]
  );

  const getRecommendationProblemContracts = useCallback(
    async (recommendationProblemId) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${recommendationProblemId}/target-contracts`;
      try {
        const response = await authenticatedRequest('get', url);
        return response?.data?.results?.map((res) => res) || [];
      } catch (e) {
        return {
          error: true,
          message: e.message,
        };
      }
    },
    [authenticatedRequest]
  );

  const postTransaction = useCallback(
    async (transactionData) => {
      const url = `${API_URL}/procurement-tenant/transactions`;
      const transaction = authenticatedRequest('post', url, {}, transactionData)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });
      return transaction;
    },
    [authenticatedRequest]
  );

  const putTransaction = useCallback(
    async (transactionData) => {
      const url = `${API_URL}/procurement-tenant/transactions/${transactionData.id}`;
      const transaction = authenticatedRequest('put', url, {}, transactionData)
        .then((res) => res?.data || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });
      return transaction;
    },
    [authenticatedRequest]
  );

  const getMarketsList = useCallback(async () => {
    const url = `${API_URL}/procurement/markets?ordering=market_name&page_size=10000`;
    const markets = authenticatedRequest('get', url)
      .then((res) => res?.data.results || [])
      .catch((err) => {
        return {
          error: true,
          message: err.message,
        };
      });
    return markets;
  }, [authenticatedRequest]);

  const getMarketContractsList = useCallback(
    async (marketId) => {
      const url = `${API_URL}/procurement/markets/${marketId}/contracts?ordering=frequency,frequency_coeff,name`;
      const contracts = authenticatedRequest('get', url)
        .then((res) => res?.data.results || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });
      return contracts;
    },
    [authenticatedRequest]
  );

  const getMarketDemandsList = useCallback(
    async (marketId) => {
      const url = `${API_URL}/procurement-tenant/demands?market=${marketId}`;
      const demands = authenticatedRequest('get', url)
        .then((res) => res?.data.results || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });
      return demands;
    },
    [authenticatedRequest]
  );

  const getMarketTransactionsList = useCallback(
    async (marketId, startDate, endDate) => {
      const url = `${API_URL}/procurement-tenant/transactions?market=${marketId}&production_start_date=${startDate}&production_end_date=${endDate}&page_size=10000`;
      const transactions = authenticatedRequest('get', url)
        .then((res) => res?.data.results || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });
      return transactions;
    },
    [authenticatedRequest]
  );

  const getMarketTransactionsAveragePrice = useCallback(
    async (marketId, startDate, endDate, contract) => {
      const url = `${API_URL}/procurement-tenant/transaction-stats?production_start_date=${startDate}&production_end_date=${endDate}&market=${marketId}`;
      return authenticatedRequest(
        'get',
        url +
          (contract.id
            ? `&contract=${contract.id}`
            : `&name=${encodeURIComponent(contract.name)}`)
      )
        .then((res) => {
          return (
            {
              contract_id: contract.id,
              contract_name: contract.name,
              ...res.data,
            } || {}
          );
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getTargetPriceHistory = useCallback(
    async (marketId, asOfDate) => {
      const formatedAsOfDate =
        moment(asOfDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format('YYYY-MM-DDTHH:mm:ssz') + 'Z';
      const url = `${API_URL}/procurement-tenant/situation-target-prices?market=${marketId}&as_of=${formatedAsOfDate}`;
      const targetPriceHistory = authenticatedRequest('get', url)
        .then((res) => res?.data.results || [])
        .catch(() => {
          return [];
        });
      return targetPriceHistory;
    },
    [authenticatedRequest]
  );

  const postTargetPriceHistory = useCallback(
    async (bulkTargetPriceHistory, marketId) => {
      const url = `${API_URL}/procurement-tenant/bulk-import-situation-target-prices/`;
      const body = {
        market: marketId,
        values: bulkTargetPriceHistory,
      };
      const targetPriceHistoryUpdate = await authenticatedRequest(
        'post',
        url,
        {},
        body
      )
        .then((res) => res || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
            status: err.response.status,
            errorMessage: err.response.data,
            whole: err,
          };
        });
      return targetPriceHistoryUpdate;
    },
    [authenticatedRequest]
  );

  const getConsumptionCsv = useCallback(
    async (demandId, selectedDate) => {
      selectedDate = formatDateToEndOfDay(selectedDate);
      const url = `${API_URL}/procurement-tenant/demands/${demandId}/values/download_csv?as_of=${selectedDate}`;
      const csv = await authenticatedRequest('get', url).then((res) => {
        return {
          url: URL.createObjectURL(new Blob([res.data], { type: 'text/csv' })),
          name: res.headers['content-disposition'].split('filename=')[1],
        };
      });

      return csv;
    },
    [authenticatedRequest]
  );

  const getTimeSerieXlsx = useCallback(
    async (serieId, endDate, startDate = null) => {
      endDate = formatDateToEndOfDay(endDate);
      startDate = startDate
        ? `&production_start_date=${moment.utc(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')}`
        : '';
      const url = `${API_URL}/procurement/time-series/${serieId}/download_excel?production_end_date=${endDate}${startDate}`;
      const xlsx = await authenticatedRequest('get', url, {
        responseType: 'arraybuffer',
      })
        .then((res) => {
          return {
            url: URL.createObjectURL(
              new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              })
            ),
          };
        })
        .catch((e) => {
          return {
            error: true,
            message: e.message,
          };
        });

      return xlsx;
    },
    [authenticatedRequest]
  );

  const getTransactionsCsv = useCallback(
    async (marketId, selectedDate) => {
      selectedDate = formatDateToEndOfDay(selectedDate);
      const url = `${API_URL}/procurement-tenant/transactions/download_csv?market=${marketId}&production_end_date=${selectedDate}`;
      const csv = await authenticatedRequest('get', url)
        .then((res) => {
          return {
            url: URL.createObjectURL(
              new Blob([res.data], { type: 'text/csv' })
            ),
            name: res.headers['content-disposition'].split('filename=')[1],
          };
        })
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });

      return csv;
    },
    [authenticatedRequest]
  );

  const getMarketsListGraphs = useCallback(async () => {
    const url = `${API_URL}/procurement/markets-graphs`;
    return authenticatedRequest('get', url).then((res) => res.data);
  }, [authenticatedRequest]);

  const getLogo = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/logos`;
    return authenticatedRequest('get', url).then((res) => res.data || {});
  }, [authenticatedRequest]);

  const getLatestLogo = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/logos/latest`;
    return authenticatedRequest('get', url, {}).then((res) => {
      return res || {};
    });
  }, [authenticatedRequest]);

  const getTrendsSummaryTask = useCallback(
    async (runId, targetId, reportType) => {
      try {
        const url = `${API_URL}/procurement/runs/${runId}/outputs/trigger_gpt_output_report?target=${targetId}&gpt_report_type=${reportType}`;
        return await authenticatedRequest('get', url);
      } catch (error) {
        console.error('error', error);
        throw new Error(error);
      }
    },
    [authenticatedRequest]
  );

  const getPriceCorridorsSummaryTask = useCallback(
    async (runId, targetId) => {
      try {
        const url = `${API_URL}/procurement/runs/${runId}/outputs/trigger_gpt_output_report?target=${targetId}&gpt_report_type=price_corridors`;
        return await authenticatedRequest('get', url);
      } catch (error) {
        console.error('error', error);
        throw new Error(error);
      }
    },
    [authenticatedRequest]
  );

  const getPriceDriversSummaryTask = useCallback(
    async (runId, targetId) => {
      try {
        const url = `${API_URL}/procurement/runs/${runId}/outputs/trigger_gpt_output_report/?target=${targetId}&gpt_report_type=price_drivers&smoothing_window=5`;
        return await authenticatedRequest('get', url);
      } catch (error) {
        console.error('error', error);
        throw new Error(error);
      }
    },
    [authenticatedRequest]
  );

  const getBuyometerSummaryTask = useCallback(
    async (runId, targetId, startDate, endDate, maxBuyHorizon) => {
      try {
        const url = `${API_URL}/procurement/runs/${runId}/outputs/trigger_gpt_output_report?gpt_report_type=buyometer&target=${targetId}&start_date=${startDate}&end_date=${endDate}&max_buy_horizon=${maxBuyHorizon}`;

        return await authenticatedRequest('get', url);
      } catch (error) {
        console.error('error', error);
        throw new Error(error);
      }
    },
    [authenticatedRequest]
  );

  const getScenarioSummaryTask = useCallback(
    async (flowId, runId, targetId, trainingGroupId) => {
      try {
        const url = `${API_URL}/procurement/flows/${flowId}/runs/${runId}/outputs/trigger_gpt_output_report?gpt_report_type=scenarios&target=${targetId}&training_group_id=${trainingGroupId}`;
        return await authenticatedRequest('get', url);
      } catch (error) {
        console.error('error', error);
        throw new Error(error);
      }
    },
    [authenticatedRequest]
  );

  const getSummaryResult = useCallback(
    async (runId, taskId) => {
      const intervalTime = 5000;
      const maxTime = 60000;
      const startTime = Date.now();

      return new Promise((resolve) => {
        (function repeat() {
          const url = `${API_URL}/procurement/runs/${runId}/outputs/retrieve_gpt_output_report/${taskId}`;
          if (taskId) {
            authenticatedRequest('get', url, {
              signal: AbortSignal.timeout(15000),
            })
              .then((response) => {
                if (response && response.status === 200) {
                  return resolve(response.data);
                } else if (response && response.status === 204) {
                  controller.abort('Summary not ready');
                  throw Error('Retry fetching summary results');
                }
              })
              .catch(() => {
                const elapsedTime = Date.now() - startTime;
                if (elapsedTime <= maxTime) {
                  return setTimeout(repeat, intervalTime);
                } else {
                  return resolve([
                    'This summary is not available for the moment, retry later.',
                  ]);
                }
              });
          } else {
            const elapsedTime = Date.now() - startTime;
            if (elapsedTime <= maxTime) {
              return setTimeout(repeat, intervalTime);
            }
          }
        })();
      });
    },
    [authenticatedRequest, controller]
  );

  const getMarketPriceHistoryXlsx = useCallback(
    async (marketId, frequency, date) => {
      const url = `${API_URL}/procurement/markets/${marketId}/download-period-price-history-excel?frequency=${frequency}&date=${date}`;
      const xlsx = await authenticatedRequest('get', url, {
        responseType: 'arraybuffer',
        validateStatus: (status) => {
          return status <= 400;
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            return {
              error: true,
              message: JSON.parse(new TextDecoder().decode(res.data)).detail,
            };
          }
          return {
            url: URL.createObjectURL(
              new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              })
            ),
            name: res.headers['content-disposition']
              .split('filename=')[1]
              .replaceAll('"', ''),
          };
        })
        .catch((e) => {
          throw e;
        });

      return xlsx;
    },
    [authenticatedRequest]
  );

  const getPastRecommendationsStats = useCallback(
    async (decisionConfigId, startDate, endDate, onlyReference = false) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${decisionConfigId}/benchmark-stats?production_start_date=${startDate}&production_end_date=${endDate}&only_reference=${onlyReference}`;
      return authenticatedRequest('get', url).then((res) => res.data || []);
    },
    [authenticatedRequest]
  );

  const getPastRecommendationsStatsByDecisionFactor = useCallback(
    async (decisionConfigId, startDate, endDate, parameterSetFlowId) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${decisionConfigId}/benchmark-stats?production_start_date=${startDate}&production_end_date=${endDate}&flow=${parameterSetFlowId}`;
      return authenticatedRequest('get', url).then((res) => res.data || []);
    },
    [authenticatedRequest]
  );

  const getCurrentDecisionProductionFlows = useCallback(async () => {
    const url = `${API_URL}/procurement-tenant/current-decision-production-flows`;
    return authenticatedRequest('get', url)
      .then((res) => res.data.results || [])
      .catch((err) => {
        return {
          message: err.message,
        };
      });
  }, [authenticatedRequest]);

  const postDecisionFlow = useCallback(
    async (body) => {
      const url = `${API_URL}/procurement/flows`;
      return authenticatedRequest('post', url, {}, body)
        .then((res) => res || [])
        .catch((err) => {
          return {
            error: true,
            message: err.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getFlowSharedInfo = useCallback(
    async (flowSharedInfoId) => {
      const url = `${API_URL}/procurement/flow-shared-infos/${flowSharedInfoId}`;
      return authenticatedRequest('get', url)
        .then((res) => res.data || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getDecisionFlowInfoByName = useCallback(
    async (flowName) => {
      const url = `${API_URL}/procurement/flows?shared_info__flow_name=${flowName}&shared_info__type=decision`;
      return authenticatedRequest('get', url)
        .then((res) => res.data.results || [])
        .catch((err) => {
          return {
            message: err.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const manageFlowStatus = useCallback(
    async (flowId, status) => {
      const flowStatus = status ? 'activate' : 'deactivate';
      const url = `${API_URL}/procurement/flows/${flowId}/${flowStatus}`;
      try {
        const result = await authenticatedRequest('get', url);
        return result.data || {};
      } catch (error) {
        const errorMessage = error.response?.data || 'Something went wrong';
        return Promise.reject(errorMessage);
      }
    },
    [authenticatedRequest]
  );

  const getTimeSeriesUnit = useCallback(
    async (timeSerieId) => {
      const url = `${API_URL}/procurement/time-series/${timeSerieId}/unit`;
      return authenticatedRequest('get', url)
        .then((res) => res.data)
        .catch((err) => {
          return {
            message: err.message,
          };
        });
    },
    [authenticatedRequest]
  );

  const getTransactionsStats = useCallback(
    async (marketId, startDate, endDate) => {
      const url = `${API_URL}/procurement-tenant/transaction-stats?production_start_date=${startDate}&production_end_date=${endDate}&market=${marketId}`;
      try {
        const result = await authenticatedRequest('get', url);
        return (
          {
            ...result.data,
          } || {}
        );
      } catch (error) {
        return {
          error: true,
          message: error.message,
        };
      }
    },
    [authenticatedRequest]
  );

  const getProgramStats = useCallback(
    async (recommendationProblemId, selectedDate) => {
      const url = `${API_URL}/procurement-tenant/recommendation-problems/${recommendationProblemId}/program-stats?as_of_date=${selectedDate}`;
      try {
        const result = await authenticatedRequest('get', url);
        return (
          {
            ...result.data,
          } || {}
        );
      } catch (error) {
        return Promise.reject(
          (error.response && error.response.data) || 'Something went wrong'
        );
      }
    },
    [authenticatedRequest]
  );

  const getReportingSummaryGlobalCoverage = useCallback(
    async (demandId, asOfDate, startDateRange, endDateRange, targetPrice) => {
      const url = `${API_URL}/procurement-tenant/demands/${demandId}/situation-summary?as_of=${asOfDate}&target_price=${targetPrice}&start_range_date=${startDateRange}&end_range_date=${endDateRange}`;
      return authenticatedRequest('get', url)
        .then((res) => res.data)
        .catch(() => {
          return {
            isError: true,
          };
        });
    },
    [authenticatedRequest]
  );

  const getReportingSummaryGlobalTransaction = useCallback(
    async (asOfDate, startDateRange, endDateRange, marketId) => {
      const url = `${API_URL}/procurement-tenant/transaction-stats-summary?as_of=${asOfDate}&production_start_date=${startDateRange}&production_end_date=${endDateRange}&market=${marketId}`;
      return authenticatedRequest('get', url)
        .then((res) => res.data)
        .catch(() => {
          return {
            isError: true,
          };
        });
    },
    [authenticatedRequest]
  );

  return {
    activateUser,
    createUser,
    getLogo,
    getLatestLogo,
    deactivateUser,
    deleteContractBasedAlertDiffusionList,
    deleteTimeSeriesBasedAlertDiffusionList,
    deleteTransaction,
    deleteScopeInstance,
    getConsumptionCsv,
    getTimeSerieXlsx,
    getAlertsConditionTemplates,
    getBuyometerResults,
    getContract,
    getContractBasedAlertConditions,
    getContractBasedAlertDiffusionList,
    getContractBasedAlerts,
    getContractDeliveryDates,
    getContractGraphNodes,
    getContractGraphs,
    getContracts,
    getContractsAndCommodities,
    getContractsWithScenarioProductionFlow,
    getCurrency,
    getCurrentPriceDrivers,
    getDecisionConfigs,
    getPastRecommendationsStats,
    getPastRecommendationsStatsByDecisionFactor,
    getDemandValueList,
    getDemandValuesHedgedListPerContract,
    getFlow,
    deleteFlow,
    getFlowDrivers,
    getFlowHorizons,
    getFlowTimeSeries,
    getFlowsWithProductionFlows,
    getOneTimeSeries,
    getParameterSets,
    getParameterSetsByDecisionConfig,
    getPastRecommendations,
    getPastRecommendationsByDecisionFactor,
    getProductionDecisionFlows,
    getProductionFlows,
    getProductionRuns,
    getRecommendationDataToDisplay,
    getLiveRecommendationDataToDisplay,
    getRecommendationProblemContracts,
    getRecommendationRuns,
    getRecommendationTemplates,
    getRunOutputs,
    getRunOutputsbyFeatureImportance,
    getRunScenarioOutput,
    getScenarioProductionRun,
    getScenarioProductionRunOutput,
    getScenariosProductionFlows,
    getTimeSerieData,
    getTimeSerieDataAndStats,
    getTimeSerieStats,
    getTimeSeries,
    getTimeSeriesBasedAlertConditions,
    getTimeSeriesBasedAlertDiffusionList,
    getTimeSeriesBasedAlerts,
    getTimeSeriesUnit,
    getTransactions,
    getTransactionsAveragePrice,
    getTransactionsCsv,
    getTransactionsStats,
    getTrendsSummaryTask,
    getSummaryResult,
    getPriceCorridorsSummaryTask,
    getPriceDriversSummaryTask,
    getBuyometerSummaryTask,
    getScenarioSummaryTask,
    getUnitsInstance,
    getUser,
    getClientList,
    setClientActive,
    getUsers,
    getUserInstance,
    getUserList,
    getUserParameterSetSchema,
    getDecisionConfigurationInstance,
    getDecisionStandardConfigurationTemplates,
    getUserSetting,
    getUserScopeList,
    getScopeInstance,
    getScopeList,
    getTenantScopeList,
    getVolatilityHistorical,
    getWindowPriceDrivers,
    getIsTenantApp,
    postBulkDemandValuesList,
    postBulkImportConsumption,
    postBuyometerTask,
    postContractBasedAlertDiffusionList,
    postContractBasedAlertDiffusionListChannel,
    postContractBasedTrigger,
    postContractBasedTriggerConditions,
    postParameterSet,
    deleteParameterSet,
    postParameterSetEligibility,
    postTimeSeriesBasedAlertDiffusionList,
    postTimeSeriesBasedAlertDiffusionListChannel,
    postTimeSeriesBasedTrigger,
    postTimeSeriesBasedTriggerConditions,
    postTransaction,
    postUserScope,
    putTransaction,
    putContractBasedAlertDiffusionListChannel,
    putParameterSet,
    putTimeSeriesBasedAlertDiffusionListChannel,
    setTenantAdmin,
    setUserSetting,
    sendInvitationEmail,
    getBulkImportTransactionsTemplate,
    getBulkImportConsumptionTemplate,
    postBulkImportTransactions,
    getMarketsList,
    getMarketContractsList,
    getMarketDemandsList,
    getMarketTransactionsList,
    getMarketTransactionsAveragePrice,
    getMarketPriceHistoryXlsx,
    getDemandSituationList,
    getDemandInstance,
    postDemandInstance,
    getTargetPriceHistory,
    postTargetPriceHistory,
    getMarketsListGraphs,
    unsetTenantAdmin,
    getCurrentDecisionProductionFlows,
    getCurrentProductionFlow,
    getFlowSharedInfo,
    getDecisionFlowInfoByName,
    postDecisionFlow,
    manageFlowStatus,
    getDemandData,
    getReportingSummaryGlobalCoverage,
    getReportingSummaryGlobalTransaction,
    getProgramStats,
    getCommodity,
  };
}
